import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SelectListingPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SelectListingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SelectListingPage/FETCH_LISTINGS_ERROR';

export const FETCH_PROFILE_LISTING_REQUEST = 'app/SelectListingPage/FETCH_PROFILE_LISTING_REQUEST';
export const FETCH_PROFILE_LISTING_SUCCESS = 'app/SelectListingPage/FETCH_PROFILE_LISTING_SUCCESS';
export const FETCH_PROFILE_LISTING_ERROR = 'app/SelectListingPage/FETCH_PROFILE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/SelectListingPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  profileListing: null,
  showProfileListingError: null,
  showProfileListingInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const selectListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case FETCH_PROFILE_LISTING_REQUEST:
      return { ...state, showProfileListingError: null, showProfileListingInProgress: true };
    case FETCH_PROFILE_LISTING_SUCCESS: {
      return { ...state, showProfileListingError: null, profileListing: payload, showProfileListingInProgress: false }
    }
    case FETCH_PROFILE_LISTING_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showProfileListingError: payload, showProfileListingInProgress: false };

    default:
      return state;
  }
};

export default selectListingPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.SelectListingPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const showProfileListingRequest = id => ({
  type: FETCH_PROFILE_LISTING_REQUEST,
  payload: { id },
});

export const showProfileListingSuccess = response => ({
  type: FETCH_PROFILE_LISTING_SUCCESS,
  payload: response,
});

export const showProfileListingError = e => ({
  type: FETCH_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export function fetchProfileListing(config, profileListingId) {
  return (dispatch, getState, sdk) => {

    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      id: profileListingId,
      include: ['author', 'images'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(showProfileListingRequest(profileListingId));

    return sdk.listings.show(queryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));

        const imagesArray = response?.data.included?.filter(i => {
          return i.type === 'image';
        });
        const authorData = response?.data.included?.filter(i => {
          return i.type === 'user';
        });

        const listingImageId = response.data?.data?.relationships?.images?.data[0]?.id?.uuid;
        const authorId = response.data?.data?.relationships?.author?.data?.id?.uuid;
        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        const author = authorData.find(a => a.id.uuid === authorId);

        const { relationships, ...rest } = response?.data?.data;

        return sdk.users.show({
          id: new UUID(authorId),
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
          .then(response => {

            const profileImagesArray = response?.data.included?.filter(i => {
              return i.type === 'image';
            });
            const profileImageId = response.data?.data?.relationships?.profileImage?.data?.id?.uuid;
            const profileImage = profileImagesArray.find(i => i.id.uuid === profileImageId);

            const profileListingWithImages = {
              ...rest,
              author: {
                ...author,
                profileImage: profileImage
              },
              images: [listingImage],
            };

            dispatch(showProfileListingSuccess(profileListingWithImages));
            return profileListingWithImages;
          })
      })
      .catch(e => dispatch(showProfileListingError(storableError(e))));
  };
}

export const loadData = (params, search, config) => {
  return (dispatch, getState) => {
    const queryParams = parse(search);
    const page = queryParams.page || 1;
    const profileListingId = new UUID(params?.id);

    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;

    return Promise.all([
      dispatch(queryOwnListings({
        ...queryParams,
        pub_listingType: 'product-selling',
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'currentStock'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })),
      dispatch(fetchProfileListing(config, profileListingId))
    ]);
  };
};
