export const industries = [
    { key: 'Accounting', label: 'Accounting' },
    { key: 'Apparel & Fashion', label: 'Apparel & Fashion' },
    { key: 'Architecture & Planning', label: 'Architecture & Planning' },
    { key: 'Artificial Intelligence (AI)', label: 'Artificial Intelligence (AI)' },
    { key: 'Arts & Crafts', label: 'Arts & Crafts' },
    { key: 'Automotive', label: 'Automotive' },
    { key: 'Aviation & Aerospace', label: 'Aviation & Aerospace' },
    { key: 'Banking', label: 'Banking' },
    { key: 'Biotechnology', label: 'Biotechnology' },
    { key: 'Building Materials', label: 'Building Materials' },
    { key: 'Capital Markets', label: 'Capital Markets' },
    { key: 'Chemicals', label: 'Chemicals' },
    { key: 'Civil Engineering', label: 'Civil Engineering' },
    { key: 'Construction', label: 'Construction' },
    { key: 'Consumer Electronics', label: 'Consumer Electronics' },
    { key: 'Consumer Goods', label: 'Consumer Goods' },
    { key: 'Cybersecurity', label: 'Cybersecurity' },
    { key: 'Digital Marketing', label: 'Digital Marketing' },
    { key: 'Education', label: 'Education' },
    { key: 'Electrical & Electronic Manufacturing', label: 'Electrical & Electronic Manufacturing' },
    { key: 'Entertainment', label: 'Entertainment' },
    { key: 'Environmental Services', label: 'Environmental Services' },
    { key: 'Facilities Services', label: 'Facilities Services' },
    { key: 'Fishery', label: 'Fishery' },
    { key: 'Finance', label: 'Finance' },
    { key: 'Food & Beverages', label: 'Food & Beverages' },
    { key: 'Gambling & Casinos', label: 'Gambling & Casinos' },
    { key: 'Government Relations', label: 'Government Relations' },
    { key: 'Health, Wellness & Fitness', label: 'Health, Wellness & Fitness' },
    { key: 'Healthcare', label: 'Healthcare' },
    { key: 'Hospitality', label: 'Hospitality' },
    { key: 'Human Resources', label: 'Human Resources' },
    { key: 'Insurance', label: 'Insurance' },
    { key: 'International Trade & Development', label: 'International Trade & Development' },
    { key: 'IT Services & IT Consulting', label: 'IT Services & IT Consulting' },
    { key: 'Legal Services', label: 'Legal Services' },
    { key: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
    { key: 'Management Consulting', label: 'Management Consulting' },
    { key: 'Manufacturing', label: 'Manufacturing' },
    { key: 'Maritime', label: 'Maritime' },
    { key: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { key: 'Mechanical & Industrial Engineering', label: 'Mechanical & Industrial Engineering' },
    { key: 'Media & Communications', label: 'Media & Communications' },
    { key: 'Military & Defense', label: 'Military & Defense' },
    { key: 'Mining & Metals', label: 'Mining & Metals' },
    { key: 'Motion Pictures & Film', label: 'Motion Pictures & Film' },
    { key: 'Museums & Institutions', label: 'Museums & Institutions' },
    { key: 'Music', label: 'Music' },
    { key: 'Nonprofit', label: 'Nonprofit' },
    { key: 'Oil & Gas', label: 'Oil & Gas' },
    { key: 'Pharmaceuticals', label: 'Pharmaceuticals' },
    { key: 'Power & Energy', label: 'Power & Energy' },
    { key: 'Private Equity', label: 'Private Equity' },
    { key: 'Real Estate', label: 'Real Estate' },
    { key: 'Renewable Energy', label: 'Renewable Energy' },
    { key: 'Retail', label: 'Retail' },
    { key: 'Robotics', label: 'Robotics' },
    { key: 'Sports', label: 'Sports' },
    { key: 'Telecommunications', label: 'Telecommunications' },
    { key: 'Travel & Tourism', label: 'Travel & Tourism' },
    { key: 'UI & UX', label: 'UI & UX' },
    { key: 'Utilities', label: 'Utilities' },
    { key: 'Venture Capital', label: 'Venture Capital' },
    { key: 'Waste Management', label: 'Waste Management' },
    { key: 'Web, Mobile & Software Development', label: 'Web, Mobile & Software Development' },
    { key: 'Writing', label: 'Writing' }
];


export const industriesFilter = industries.map(i => ({ option: i.key, label: i.label }));
