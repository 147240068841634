import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const WalletIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7976 11.5V16.5C20.7976 19.5 18.8178 21.5 15.8482 21.5H5.94939C2.97976 21.5 1 19.5 1 16.5V11.5C1 8.78 2.6234 6.88 5.14759 6.56C5.40496 6.52 5.67223 6.5 5.94939 6.5H15.8482C16.1056 6.5 16.353 6.50999 16.5906 6.54999C19.1445 6.84999 20.7976 8.76 20.7976 11.5Z" stroke="#012E46" strokeWidth="1.44362" strokeLinecap="round" strokeLinejoin="round" fill="#ffffff" />
      <path d="M16.5919 6.55C16.3543 6.51 16.1069 6.50001 15.8495 6.50001H5.95073C5.67356 6.50001 5.40629 6.52001 5.14893 6.56001C5.28751 6.28001 5.48548 6.02001 5.72306 5.78001L8.94015 2.52C10.2963 1.16 12.4938 1.16 13.85 2.52L15.5822 4.29002C16.2158 4.92002 16.5523 5.72 16.5919 6.55Z" stroke="#012E46" strokeWidth="1.44362" strokeLinecap="round" strokeLinejoin="round" fill="#ffffff" />
      <path d="M20.7975 12H17.8279C16.739 12 15.8481 12.9 15.8481 14C15.8481 15.1 16.739 16 17.8279 16H20.7975" stroke="#012E46" strokeWidth="1.44362" strokeLinecap="round" strokeLinejoin="round" fill="#ffffff" />
    </svg>
  );
};

export default WalletIcon;
