import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const DropdownIcon = props => {
  const { isHoveredDropdownIcon, currentPage } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        fill: 'none',
        transform: isHoveredDropdownIcon ? 'rotate(180deg)' : null,
        transition: 'transform 0.2s ease',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={
          currentPage
            ? '#cc9b0a'
            : isHoveredDropdownIcon
              ? '#cc9b0a'
              : '#013957'
        }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9L12 15L18 9"
        // stroke="black"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropdownIcon;
