export const skillOptions = [
    { key: '3D animation software', label: '3D animation software' },
    { key: '3D modeling', label: '3D modeling' },
    { key: '3D modeling and animation: Maya, Blender, and more.', label: '3D modeling and animation: Maya, Blender, and more.' },
    { key: '3D modeling software', label: '3D modeling software' },
    { key: '3D printing', label: '3D printing' },
    { key: '3D printing and additive manufacturing', label: '3D printing and additive manufacturing' },
    { key: '5G technology', label: '5G technology' },
    { key: 'Ability to adapt to changing customer needs', label: 'Ability to adapt to changing customer needs' },
    { key: 'Ability to adapt to changing market conditions', label: 'Ability to adapt to changing market conditions' },
    { key: 'Ability to adapt to new technologies and scientific trends', label: 'Ability to adapt to new technologies and scientific trends' },
    { key: 'Ability to analyze and interpret data to make informed decisions', label: 'Ability to analyze and interpret data to make informed decisions' },
    { key: 'Ability to analyze and interpret experimental data', label: 'Ability to analyze and interpret experimental data' },
    { key: 'Ability to analyze and interpret market data', label: 'Ability to analyze and interpret market data' },
    { key: 'Ability to apply scientific knowledge to solve practical', label: 'Ability to apply scientific knowledge to solve practical' },
    { key: 'Ability to be flexible and adaptable', label: 'Ability to be flexible and adaptable' },
    { key: 'Ability to build and manage a team of professionals', label: 'Ability to build and manage a team of professionals' },
    { key: 'Ability to collaborate with other businesses and organizations', label: 'Ability to collaborate with other businesses and organizations' },
    { key: 'Ability to collaborate with others', label: 'Ability to collaborate with others' },
    { key: 'Ability to communicate complex concepts to non-experts', label: 'Ability to communicate complex concepts to non-experts' },
    { key: 'Ability to communicate scientific concepts to non-scientific audiences', label: 'Ability to communicate scientific concepts to non-scientific audiences' },
    { key: 'Ability to conduct interviews and write feature stories', label: 'Ability to conduct interviews and write feature stories' },
    { key: 'Ability to create and execute influencer marketing campaigns', label: 'Ability to create and execute influencer marketing campaigns' },
    { key: 'Ability to create and execute marketing campaigns', label: 'Ability to create and execute marketing campaigns' },
    { key: 'Ability to create and maintain a positive company culture', label: 'Ability to create and maintain a positive company culture' },
    { key: 'Ability to create and maintain a safe working environment', label: 'Ability to create and maintain a safe working environment' },
    { key: 'Ability to create and manage affiliate marketing programs', label: 'Ability to create and manage affiliate marketing programs' },
    { key: 'Ability to create and manage editorial calendars', label: 'Ability to create and manage editorial calendars' },
    { key: 'Ability to create financial projections', label: 'Ability to create financial projections' },
    { key: 'Ability to delegate tasks effectively', label: 'Ability to delegate tasks effectively' },
    { key: 'Ability to design and implement effective landing pages', label: 'Ability to design and implement effective landing pages' },
    { key: 'Ability to design and perform experiments', label: 'Ability to design and perform experiments' },
    { key: 'Ability to develop and deliver scientific presentations', label: 'Ability to develop and deliver scientific presentations' },
    { key: 'Ability to develop and execute A/B testing campaigns', label: 'Ability to develop and execute A/B testing campaigns' },
    { key: 'Ability to develop and execute account-based marketing campaigns', label: 'Ability to develop and execute account-based marketing campaigns' },
    { key: 'Ability to develop and execute digital PR strategies', label: 'Ability to develop and execute digital PR strategies' },
    { key: 'Ability to develop and execute marketing strategies', label: 'Ability to develop and execute marketing strategies' },
    { key: 'Ability to develop and execute pricing strategies', label: 'Ability to develop and execute pricing strategies' },
    { key: 'Ability to develop and execute research projects', label: 'Ability to develop and execute research projects' },
    { key: 'Ability to develop and execute sales strategies', label: 'Ability to develop and execute sales strategies' },
    { key: 'Ability to develop and execute seasonal menus', label: 'Ability to develop and execute seasonal menus' },
    { key: 'Ability to develop and implement scientific models', label: 'Ability to develop and implement scientific models' },
    { key: 'Ability to develop and maintain collaborations', label: 'Ability to develop and maintain collaborations' },
    { key: 'Ability to develop and teach scientific courses', label: 'Ability to develop and teach scientific courses' },
    { key: 'Ability to develop and test hypotheses', label: 'Ability to develop and test hypotheses' },
    { key: 'Ability to engage and connect with an audience', label: 'Ability to engage and connect with an audience' },
    { key: 'Ability to evaluate and critique scientific literature', label: 'Ability to evaluate and critique scientific literature' },
    { key: 'Ability to generate ideas and pitch them to clients or editors', label: 'Ability to generate ideas and pitch them to clients or editors' },
    { key: 'Ability to handle cash and financial transactions', label: 'Ability to handle cash and financial transactions' },
    { key: 'Ability to handle criticism and rejection', label: 'Ability to handle criticism and rejection' },
    { key: 'Ability to handle customer complaints and feedback', label: 'Ability to handle customer complaints and feedback' },
    { key: 'Ability to identify and capitalize on opportunities', label: 'Ability to identify and capitalize on opportunities' },
    { key: 'Ability to identify and develop talent', label: 'Ability to identify and develop talent' },
    { key: 'Ability to identify and resolve conflicts', label: 'Ability to identify and resolve conflicts' },
    { key: 'Ability to identify and solve scientific problems', label: 'Ability to identify and solve scientific problems' },
    { key: 'Ability to innovate and develop new scientific techniques', label: 'Ability to innovate and develop new scientific techniques' },
    { key: 'Ability to innovate and find new solutions to problems', label: 'Ability to innovate and find new solutions to problems' },
    { key: 'Ability to lead and motivate a team', label: 'Ability to lead and motivate a team' },
    { key: 'Ability to maintain a high level of accuracy and attention to detail', label: 'Ability to maintain a high level of accuracy and attention to detail' },
    { key: 'Ability to maintain a positive attitude', label: 'Ability to maintain a positive attitude' },
    { key: 'Ability to maintain a professional demeanor in all situations', label: 'Ability to maintain a professional demeanor in all situations' },
    { key: 'Ability to maintain confidentiality', label: 'Ability to maintain confidentiality' },
    { key: 'Ability to maintain high standards of hygiene and cleanliness', label: 'Ability to maintain high standards of hygiene and cleanliness' },
    { key: 'Ability to make tough decisions', label: 'Ability to make tough decisions' },
    { key: 'Ability to manage a budget', label: 'Ability to manage a budget' },
    { key: 'Ability to manage a timeline', label: 'Ability to manage a timeline' },
    { key: 'Ability to manage and analyze financial data', label: 'Ability to manage and analyze financial data' },
    { key: 'Ability to manage and analyze sales data', label: 'Ability to manage and analyze sales data' },
    { key: 'Ability to manage and execute catering events', label: 'Ability to manage and execute catering events' },
    { key: 'Ability to manage and execute charity events and donations', label: 'Ability to manage and execute charity events and donations' },
    { key: 'Ability to manage and execute large-scale events', label: 'Ability to manage and execute large-scale events' },
    { key: 'Ability to manage and execute pop-up events', label: 'Ability to manage and execute pop-up events' },
    { key: 'Ability to manage and execute private events and parties', label: 'Ability to manage and execute private events and parties' },
    { key: 'Ability to manage and grow a team of digital marketers', label: 'Ability to manage and grow a team of digital marketers' },
    { key: 'Ability to manage and monitor labor costs', label: 'Ability to manage and monitor labor costs' },
    { key: 'Ability to manage and operate food trucks and mobile kitchens', label: 'Ability to manage and operate food trucks and mobile kitchens' },
    { key: 'Ability to manage and operate multiple restaurant locations', label: 'Ability to manage and operate multiple restaurant locations' },
    { key: 'Ability to manage and optimize campaigns across multiple channels', label: 'Ability to manage and optimize campaigns across multiple channels' },
    { key: 'Ability to manage and optimize email', label: 'Ability to manage and optimize email' },
    { key: 'Ability to manage and optimize email deliverability', label: 'Ability to manage and optimize email deliverability' },
    { key: 'Ability to manage and prioritize multiple projects', label: 'Ability to manage and prioritize multiple projects' },
    { key: 'Ability to manage and track inventory', label: 'Ability to manage and track inventory' },
    { key: 'Ability to manage and train staff on food safety protocols', label: 'Ability to manage and train staff on food safety protocols' },
    { key: 'Ability to manage client expectations', label: 'Ability to manage client expectations' },
    { key: 'Ability to manage conflict effectively', label: 'Ability to manage conflict effectively' },
    { key: 'Ability to manage expectations of clients and stakeholders', label: 'Ability to manage expectations of clients and stakeholders' },
    { key: 'Ability to manage food waste and sustainability', label: 'Ability to manage food waste and sustainability' },
    { key: 'Ability to manage multiple priorities', label: 'Ability to manage multiple priorities' },
    { key: 'Ability to manage multiple projects at once', label: 'Ability to manage multiple projects at once' },
    { key: 'Ability to manage relationships with local suppliers and farmers', label: 'Ability to manage relationships with local suppliers and farmers' },
    { key: 'Ability to manage research budgets', label: 'Ability to manage research budgets' },
    { key: 'Ability to manage social media and engage with followers', label: 'Ability to manage social media and engage with followers' },
    { key: 'Ability to manage social media and online presence', label: 'Ability to manage social media and online presence' },
    { key: 'Ability to mentor and coach junior professionals', label: 'Ability to mentor and coach junior professionals' },
    { key: 'Ability to mentor and train junior scientists', label: 'Ability to mentor and train junior scientists' },
    { key: 'Ability to monitor and analyze customer feedback', label: 'Ability to monitor and analyze customer feedback' },
    { key: 'Ability to motivate and inspire a team', label: 'Ability to motivate and inspire a team' },
    { key: 'Ability to network and build professional relationships', label: 'Ability to network and build professional relationships' },
    { key: 'Ability to organize and participate in scientific conferences', label: 'Ability to organize and participate in scientific conferences' },
    { key: 'Ability to perform literature reviews', label: 'Ability to perform literature reviews' },
    { key: 'Ability to present information in a clear and concise manner', label: 'Ability to present information in a clear and concise manner' },
    { key: 'Ability to present research findings to various audiences', label: 'Ability to present research findings to various audiences' },
    { key: 'Ability to prioritize tasks effectively', label: 'Ability to prioritize tasks effectively' },
    { key: 'Ability to read and interpret financial statements', label: 'Ability to read and interpret financial statements' },
    { key: 'Ability to remain calm under pressure', label: 'Ability to remain calm under pressure' },
    { key: 'Ability to research and gather information', label: 'Ability to research and gather information' },
    { key: 'Ability to source and select high-quality ingredients', label: 'Ability to source and select high-quality ingredients' },
    { key: 'Ability to stay up-to-date with industry trends and developments', label: 'Ability to stay up-to-date with industry trends and developments' },
    { key: 'Ability to think creatively', label: 'Ability to think creatively' },
    { key: 'Ability to think creatively and outside of the box', label: 'Ability to think creatively and outside of the box' },
    { key: 'Ability to think strategically', label: 'Ability to think strategically' },
    { key: 'Ability to troubleshoot experimental equipment and techniques', label: 'Ability to troubleshoot experimental equipment and techniques' },
    { key: 'Ability to understand and manage risk', label: 'Ability to understand and manage risk' },
    { key: 'Ability to use and maintain POS systems', label: 'Ability to use and maintain POS systems' },
    { key: 'Ability to work collaboratively with other departments within a company', label: 'Ability to work collaboratively with other departments within a company' },
    { key: 'Ability to work in a fast-paced environment', label: 'Ability to work in a fast-paced environment' },
    { key: 'Ability to work in a high-pressure environment', label: 'Ability to work in a high-pressure environment' },
    { key: 'Ability to work in a team', label: 'Ability to work in a team' },
    { key: 'Ability to work independently', label: 'Ability to work independently' },
    { key: 'Ability to work independently and as part of a team', label: 'Ability to work independently and as part of a team' },
    { key: 'Ability to work long hours', label: 'Ability to work long hours' },
    { key: 'Ability to work under pressure and meet deadlines', label: 'Ability to work under pressure and meet deadlines' },
    { key: 'Ability to work with a diverse group of people', label: 'Ability to work with a diverse group of people' },
    { key: 'Ability to work with a diverse range of people', label: 'Ability to work with a diverse range of people' },
    { key: 'Ability to work with diverse teams and stakeholders', label: 'Ability to work with diverse teams and stakeholders' },
    { key: 'Ability to write and obtain research grants', label: 'Ability to write and obtain research grants' },
    { key: 'Ability to write and publish scientific papers', label: 'Ability to write and publish scientific papers' },
    { key: 'Ability to write compelling sales copy', label: 'Ability to write compelling sales copy' },
    { key: 'Ability to write effective ad copy and landing page copy', label: 'Ability to write effective ad copy and landing page copy' },
    { key: 'Ability to write engaging and interactive content', label: 'Ability to write engaging and interactive content' },
    { key: 'Ability to write for B2B audiences and businesses', label: 'Ability to write for B2B audiences and businesses' },
    { key: 'Ability to write for different formats, such as whitepapers or case studies', label: 'Ability to write for different formats, such as whitepapers or case studies' },
    { key: 'Ability to write for different niches and industries', label: 'Ability to write for different niches and industries' },
    { key: 'Ability to write for different platforms and mediums', label: 'Ability to write for different platforms and mediums' },
    { key: 'Ability to write for different stages of the customer journey', label: 'Ability to write for different stages of the customer journey' },
    { key: 'Ability to write for different target personas and demographics', label: 'Ability to write for different target personas and demographics' },
    { key: 'Ability to write for e-commerce and online retail', label: 'Ability to write for e-commerce and online retail' },
    { key: 'Ability to write for emerging technologies, such as AI or VR', label: 'Ability to write for emerging technologies, such as AI or VR' },
    { key: 'Ability to write for entertainment and pop culture', label: 'Ability to write for entertainment and pop culture' },
    { key: 'Ability to write for finance and business', label: 'Ability to write for finance and business' },
    { key: 'Ability to write for food and culinary industries', label: 'Ability to write for food and culinary industries' },
    { key: 'Ability to write for health and wellness', label: 'Ability to write for health and wellness' },
    { key: 'Ability to write for international audiences and different languages', label: 'Ability to write for international audiences and different languages' },
    { key: 'Ability to write for legal and regulatory compliance', label: 'Ability to write for legal and regulatory compliance' },
    { key: 'Ability to write for mobile devices and platforms', label: 'Ability to write for mobile devices and platforms' },
    { key: 'Ability to write for news and current events', label: 'Ability to write for news and current events' },
    { key: 'Ability to write for niche markets and communities', label: 'Ability to write for niche markets and communities' },
    { key: 'Ability to write for non-profit or cause-based organizations', label: 'Ability to write for non-profit or cause-based organizations' },
    { key: 'Ability to write for SEO and keyword optimization', label: 'Ability to write for SEO and keyword optimization' },
    { key: 'Ability to write for social media platforms, such as Twitter or Instagram', label: 'Ability to write for social media platforms, such as Twitter or Instagram' },
    { key: 'Ability to write for the education sector', label: 'Ability to write for the education sector' },
    { key: 'Ability to write for travel and tourism', label: 'Ability to write for travel and tourism' },
    { key: 'Ability to write headlines and subheadings that grab attention', label: 'Ability to write headlines and subheadings that grab attention' },
    { key: 'Ability to write how-to guides and tutorials', label: 'Ability to write how-to guides and tutorials' },
    { key: 'Ability to write long-form content and articles', label: 'Ability to write long-form content and articles' },
    { key: 'Ability to write persuasive and compelling content', label: 'Ability to write persuasive and compelling content' },
    { key: 'Ability to write product reviews and comparisons', label: 'Ability to write product reviews and comparisons' },
    { key: 'Account management', label: 'Account management' },
    { key: 'Account-based marketing', label: 'Account-based marketing' },
    { key: 'Accountability', label: 'Accountability' },
    { key: 'Accounting', label: 'Accounting' },
    { key: 'Accounting and finance', label: 'Accounting and finance' },
    { key: 'Acoustics', label: 'Acoustics' },
    { key: 'Acoustics knowledge and skills', label: 'Acoustics knowledge and skills' },
    { key: 'Acting and performance', label: 'Acting and performance' },
    { key: 'Acting and performance skills', label: 'Acting and performance skills' },
    { key: 'Active listening', label: 'Active listening' },
    { key: 'Active listening and empathy', label: 'Active listening and empathy' },
    { key: 'Adaptability', label: 'Adaptability' },
    { key: 'Adaptability and flexibility', label: 'Adaptability and flexibility' },
    { key: 'Adaptability and resilience', label: 'Adaptability and resilience' },
    { key: 'Adaptability and resilience.', label: 'Adaptability and resilience.' },
    { key: 'Adaptive reuse', label: 'Adaptive reuse' },
    { key: 'Administrative law', label: 'Administrative law' },
    { key: 'Adobe Premiere Pro', label: 'Adobe Premiere Pro' },
    { key: 'Advertising budgeting and forecasting', label: 'Advertising budgeting and forecasting' },
    { key: 'Advertising campaign planning and execution', label: 'Advertising campaign planning and execution' },
    { key: 'Advertising collateral creation', label: 'Advertising collateral creation' },
    { key: 'Advocacy', label: 'Advocacy' },
    { key: 'Advocacy for the arts', label: 'Advocacy for the arts' },
    { key: 'Advocacy skills', label: 'Advocacy skills' },
    { key: 'Aerospace engineering', label: 'Aerospace engineering' },
    { key: 'Aesthetic sensibility and appreciation of design', label: 'Aesthetic sensibility and appreciation of design' },
    { key: 'Aesthetics', label: 'Aesthetics' },
    { key: 'Aesthetics and design principles', label: 'Aesthetics and design principles' },
    { key: 'Affiliate marketing', label: 'Affiliate marketing' },
    { key: 'Agile development', label: 'Agile development' },
    { key: 'Agile development methodologies', label: 'Agile development methodologies' },
    { key: 'Agile marketing methodology', label: 'Agile marketing methodology' },
    { key: 'Agile methodology', label: 'Agile methodology' },
    { key: 'Agile methodology: Scrum, Kanban, and more.', label: 'Agile methodology: Scrum, Kanban, and more.' },
    { key: 'Agile project management', label: 'Agile project management' },
    { key: 'Agribusiness finance and accounting', label: 'Agribusiness finance and accounting' },
    { key: 'Agricultural accounting and financial reporting', label: 'Agricultural accounting and financial reporting' },
    { key: 'Agricultural biodiversity conservation', label: 'Agricultural biodiversity conservation' },
    { key: 'Agricultural biosecurity and disease management', label: 'Agricultural biosecurity and disease management' },
    { key: 'Agricultural biotechnology and genetic engineering', label: 'Agricultural biotechnology and genetic engineering' },
    { key: 'Agricultural carbon and greenhouse gas accounting', label: 'Agricultural carbon and greenhouse gas accounting' },
    { key: 'Agricultural climate change adaptation and mitigation', label: 'Agricultural climate change adaptation and mitigation' },
    { key: 'Agricultural commodity price analysis', label: 'Agricultural commodity price analysis' },
    { key: 'Agricultural commodity trading and futures markets', label: 'Agricultural commodity trading and futures markets' },
    { key: 'Agricultural communications and public relations', label: 'Agricultural communications and public relations' },
    { key: 'Agricultural cooperative management', label: 'Agricultural cooperative management' },
    { key: 'Agricultural data analysis and visualization', label: 'Agricultural data analysis and visualization' },
    { key: 'Agricultural data management and analysis', label: 'Agricultural data management and analysis' },
    { key: 'Agricultural data privacy and security', label: 'Agricultural data privacy and security' },
    { key: 'Agricultural disaster insurance and compensation', label: 'Agricultural disaster insurance and compensation' },
    { key: 'Agricultural disaster management and emergency response', label: 'Agricultural disaster management and emergency response' },
    { key: 'Agricultural dispute resolution and litigation', label: 'Agricultural dispute resolution and litigation' },
    { key: 'Agricultural dispute resolution and mediation', label: 'Agricultural dispute resolution and mediation' },
    { key: 'Agricultural drainage and wetland management', label: 'Agricultural drainage and wetland management' },
    { key: 'Agricultural ecosystem services valuation', label: 'Agricultural ecosystem services valuation' },
    { key: 'Agricultural education and training programs', label: 'Agricultural education and training programs' },
    { key: 'Agricultural energy and resource management', label: 'Agricultural energy and resource management' },
    { key: 'Agricultural engineering and equipment operation', label: 'Agricultural engineering and equipment operation' },
    { key: 'Agricultural entrepreneurship and startups', label: 'Agricultural entrepreneurship and startups' },
    { key: 'Agricultural extension and education techniques', label: 'Agricultural extension and education techniques' },
    { key: 'Agricultural financing and investment strategies', label: 'Agricultural financing and investment strategies' },
    { key: 'Agricultural financing and loan management', label: 'Agricultural financing and loan management' },
    { key: 'Agricultural food safety and quality assurance', label: 'Agricultural food safety and quality assurance' },
    { key: 'Agricultural food traceability and tracking systems', label: 'Agricultural food traceability and tracking systems' },
    { key: 'Agricultural input pricing and procurement', label: 'Agricultural input pricing and procurement' },
    { key: 'Agricultural input supply chain management', label: 'Agricultural input supply chain management' },
    { key: 'Agricultural insurance and risk management', label: 'Agricultural insurance and risk management' },
    { key: 'Agricultural insurance and risk management products', label: 'Agricultural insurance and risk management products' },
    { key: 'Agricultural intellectual property rights management', label: 'Agricultural intellectual property rights management' },
    { key: 'Agricultural labor laws and regulations', label: 'Agricultural labor laws and regulations' },
    { key: 'Agricultural labor management and hiring practices', label: 'Agricultural labor management and hiring practices' },
    { key: 'Agricultural labor safety and health management', label: 'Agricultural labor safety and health management' },
    { key: 'Agricultural labor training and certification', label: 'Agricultural labor training and certification' },
    { key: 'Agricultural land appraisal and valuation', label: 'Agricultural land appraisal and valuation' },
    { key: 'Agricultural land conservation and management', label: 'Agricultural land conservation and management' },
    { key: 'Agricultural land tenure and ownership rights', label: 'Agricultural land tenure and ownership rights' },
    { key: 'Agricultural land use planning and management', label: 'Agricultural land use planning and management' },
    { key: 'Agricultural machinery and equipment maintenance', label: 'Agricultural machinery and equipment maintenance' },
    { key: 'Agricultural market analysis and forecasting', label: 'Agricultural market analysis and forecasting' },
    { key: 'Agricultural marketing and sales strategies', label: 'Agricultural marketing and sales strategies' },
    { key: 'Agricultural marketing research and analysis', label: 'Agricultural marketing research and analysis' },
    { key: 'Agricultural nutrient management planning', label: 'Agricultural nutrient management planning' },
    { key: 'Agricultural pest monitoring and management', label: 'Agricultural pest monitoring and management' },
    { key: 'Agricultural plant protection product development', label: 'Agricultural plant protection product development' },
    { key: 'Agricultural policy advocacy and lobbying', label: 'Agricultural policy advocacy and lobbying' },
    { key: 'Agricultural policy and regulation knowledge', label: 'Agricultural policy and regulation knowledge' },
    { key: 'Agricultural precision fertilization technologies', label: 'Agricultural precision fertilization technologies' },
    { key: 'Agricultural precision irrigation technologies', label: 'Agricultural precision irrigation technologies' },
    { key: 'Agricultural precision planting technologies', label: 'Agricultural precision planting technologies' },
    { key: 'Agricultural product certification requirements', label: 'Agricultural product certification requirements' },
    { key: 'Agricultural product development and innovation', label: 'Agricultural product development and innovation' },
    { key: 'Agricultural product labeling and certification', label: 'Agricultural product labeling and certification' },
    { key: 'Agricultural product lifecycle assessment', label: 'Agricultural product lifecycle assessment' },
    { key: 'Agricultural production system optimization', label: 'Agricultural production system optimization' },
    { key: 'Agricultural remote sensing and monitoring technologies', label: 'Agricultural remote sensing and monitoring technologies' },
    { key: 'Agricultural soil health monitoring and management', label: 'Agricultural soil health monitoring and management' },
    { key: 'Agricultural supply chain logistics', label: 'Agricultural supply chain logistics' },
    { key: 'Agricultural supply chain sustainability', label: 'Agricultural supply chain sustainability' },
    { key: 'Agricultural supply chain traceability and transparency', label: 'Agricultural supply chain traceability and transparency' },
    { key: 'Agricultural systems modeling and simulation', label: 'Agricultural systems modeling and simulation' },
    { key: 'Agricultural trade financing and insurance', label: 'Agricultural trade financing and insurance' },
    { key: 'Agricultural trade negotiations and agreements', label: 'Agricultural trade negotiations and agreements' },
    { key: 'Agricultural waste management and recycling', label: 'Agricultural waste management and recycling' },
    { key: 'Agricultural waste reduction and recycling', label: 'Agricultural waste reduction and recycling' },
    { key: 'Agricultural water management and conservation', label: 'Agricultural water management and conservation' },
    { key: 'Agronomy and crop science research methods', label: 'Agronomy and crop science research methods' },
    { key: 'Air pollution control and management', label: 'Air pollution control and management' },
    { key: 'Air quality monitoring and assessment', label: 'Air quality monitoring and assessment' },
    { key: 'Algorithm design', label: 'Algorithm design' },
    { key: 'Alternative dispute resolution', label: 'Alternative dispute resolution' },
    { key: 'Alternative investments', label: 'Alternative investments' },
    { key: 'Analytical and critical thinking abilities', label: 'Analytical and critical thinking abilities' },
    { key: 'Analytical skills', label: 'Analytical skills' },
    { key: 'Analytical thinking', label: 'Analytical thinking' },
    { key: 'Analytics', label: 'Analytics' },
    { key: 'Animal health and disease prevention', label: 'Animal health and disease prevention' },
    { key: 'Animal nutrition and feed management', label: 'Animal nutrition and feed management' },
    { key: 'Animation', label: 'Animation' },
    { key: 'Animation and CGI skills', label: 'Animation and CGI skills' },
    { key: 'Animation and motion graphics', label: 'Animation and motion graphics' },
    { key: 'Animation software', label: 'Animation software' },
    { key: 'Anti-money laundering', label: 'Anti-money laundering' },
    { key: 'Anti-trust law', label: 'Anti-trust law' },
    { key: 'API development: REST, GraphQL, and more.', label: 'API development: REST, GraphQL, and more.' },
    { key: 'Applicant tracking systems', label: 'Applicant tracking systems' },
    { key: 'Aquaculture and fish farming techniques', label: 'Aquaculture and fish farming techniques' },
    { key: 'Arbitration and mediation', label: 'Arbitration and mediation' },
    { key: 'Architectural design', label: 'Architectural design' },
    { key: 'Architectural history', label: 'Architectural history' },
    { key: 'Archiving and records management', label: 'Archiving and records management' },
    { key: 'Art conservation and restoration', label: 'Art conservation and restoration' },
    { key: 'Art curation and exhibition planning', label: 'Art curation and exhibition planning' },
    { key: 'Art direction and graphic design', label: 'Art direction and graphic design' },
    { key: 'Art history and theory', label: 'Art history and theory' },
    { key: 'Artificial intelligence', label: 'Artificial intelligence' },
    { key: 'Artificial intelligence (AI) and machine learning (ML) implementation', label: 'Artificial intelligence (AI) and machine learning (ML) implementation' },
    { key: 'Artificial intelligence development', label: 'Artificial intelligence development' },
    { key: 'Artistic vision and direction', label: 'Artistic vision and direction' },
    { key: 'Arts and culture management.', label: 'Arts and culture management.' },
    { key: 'Arts education and instruction', label: 'Arts education and instruction' },
    { key: 'Arts journalism and criticism', label: 'Arts journalism and criticism' },
    { key: 'Asset allocation', label: 'Asset allocation' },
    { key: 'Asset management', label: 'Asset management' },
    { key: 'Asset management.', label: 'Asset management.' },
    { key: 'Asset valuation', label: 'Asset valuation' },
    { key: 'Astronomy knowledge and skills', label: 'Astronomy knowledge and skills' },
    { key: 'Astrophysics knowledge and skills', label: 'Astrophysics knowledge and skills' },
    { key: 'Atomic physics knowledge and skills', label: 'Atomic physics knowledge and skills' },
    { key: 'Attention to detail', label: 'Attention to detail' },
    { key: 'Attention to detail and accuracy', label: 'Attention to detail and accuracy' },
    { key: 'Attention to detail and quality control', label: 'Attention to detail and quality control' },
    { key: 'Audience engagement and community outreach', label: 'Audience engagement and community outreach' },
    { key: 'Audience engagement and interaction', label: 'Audience engagement and interaction' },
    { key: 'Audience research and segmentation', label: 'Audience research and segmentation' },
    { key: 'Audience segmentation', label: 'Audience segmentation' },
    { key: 'Audience segmentation and analysis', label: 'Audience segmentation and analysis' },
    { key: 'Audio and video production: Adobe Premiere Pro, Adobe Audition, and more.', label: 'Audio and video production: Adobe Premiere Pro, Adobe Audition, and more.' },
    { key: 'Audio engineering', label: 'Audio engineering' },
    { key: 'Audio equipment maintenance', label: 'Audio equipment maintenance' },
    { key: 'Audio post-production workflow', label: 'Audio post-production workflow' },
    { key: 'Audio processing', label: 'Audio processing' },
    { key: 'Audio production and editing', label: 'Audio production and editing' },
    { key: 'Audiovisual (AV) installation and setup', label: 'Audiovisual (AV) installation and setup' },
    { key: 'Audiovisual technology and engineering', label: 'Audiovisual technology and engineering' },
    { key: 'Audit', label: 'Audit' },
    { key: 'Audit and compliance', label: 'Audit and compliance' },
    { key: 'Augmented and virtual reality (AR/VR) design and development', label: 'Augmented and virtual reality (AR/VR) design and development' },
    { key: 'Augmented reality', label: 'Augmented reality' },
    { key: 'Augmented reality and virtual reality', label: 'Augmented reality and virtual reality' },
    { key: 'Augmented reality development', label: 'Augmented reality development' },
    { key: 'Augmented reality development: ARKit, ARCore, and more.', label: 'Augmented reality development: ARKit, ARCore, and more.' },
    { key: 'Automation', label: 'Automation' },
    { key: 'Avid Media Composer', label: 'Avid Media Composer' },
    { key: 'Awareness of analytics and tracking tools', label: 'Awareness of analytics and tracking tools' },
    { key: 'Back-end development', label: 'Back-end development' },
    { key: 'Back-end web development', label: 'Back-end web development' },
    { key: 'Back-end web development: Node.js, Express.js, Flask, Django, Ruby on Rails, Spring Boot, Laravel, and more.', label: 'Back-end web development: Node.js, Express.js, Flask, Django, Ruby on Rails, Spring Boot, Laravel, and more.' },
    { key: 'Banking law', label: 'Banking law' },
    { key: 'Bankruptcy law', label: 'Bankruptcy law' },
    { key: 'Base station site acquisition', label: 'Base station site acquisition' },
    { key: 'Basic understanding of HTML and CSS coding', label: 'Basic understanding of HTML and CSS coding' },
    { key: 'Basic understanding of web design and UX principles', label: 'Basic understanding of web design and UX principles' },
    { key: 'Battery energy storage', label: 'Battery energy storage' },
    { key: 'Behavioral economics', label: 'Behavioral economics' },
    { key: 'Behavioral interviewing', label: 'Behavioral interviewing' },
    { key: 'Benefits administration', label: 'Benefits administration' },
    { key: 'Biomass energy technologies and development', label: 'Biomass energy technologies and development' },
    { key: 'Biomass power', label: 'Biomass power' },
    { key: 'Biophysics knowledge and skills', label: 'Biophysics knowledge and skills' },
    { key: 'Blockchain', label: 'Blockchain' },
    { key: 'Blockchain development: Ethereum, Solidity, and more.', label: 'Blockchain development: Ethereum, Solidity, and more.' },
    { key: 'Brand identity development', label: 'Brand identity development' },
    { key: 'Brand management', label: 'Brand management' },
    { key: 'Brand strategy development', label: 'Brand strategy development' },
    { key: 'Branding and identity management', label: 'Branding and identity management' },
    { key: 'Branding and marketing skills', label: 'Branding and marketing skills' },
    { key: 'Branding and marketing strategy', label: 'Branding and marketing strategy' },
    { key: 'Budget forecasting and financial analysis', label: 'Budget forecasting and financial analysis' },
    { key: 'Budget management', label: 'Budget management' },
    { key: 'Budget management software', label: 'Budget management software' },
    { key: 'Budgeting', label: 'Budgeting' },
    { key: 'Budgeting and finance', label: 'Budgeting and finance' },
    { key: 'Budgeting and financial management', label: 'Budgeting and financial management' },
    { key: 'Budgeting and financial management skills', label: 'Budgeting and financial management skills' },
    { key: 'Budgeting and forecasting for arts projects and events', label: 'Budgeting and forecasting for arts projects and events' },
    { key: 'Building automation systems', label: 'Building automation systems' },
    { key: 'Building codes and regulations', label: 'Building codes and regulations' },
    { key: 'Building construction', label: 'Building construction' },
    { key: 'Building information modeling (BIM)', label: 'Building information modeling (BIM)' },
    { key: 'Building performance analysis', label: 'Building performance analysis' },
    { key: 'Building relationships with appraisers', label: 'Building relationships with appraisers' },
    { key: 'Building relationships with attorneys', label: 'Building relationships with attorneys' },
    { key: 'Building relationships with brokers', label: 'Building relationships with brokers' },
    { key: 'Building relationships with community leaders', label: 'Building relationships with community leaders' },
    { key: 'Building relationships with contractors', label: 'Building relationships with contractors' },
    { key: 'Building relationships with developers', label: 'Building relationships with developers' },
    { key: 'Building relationships with government officials', label: 'Building relationships with government officials' },
    { key: 'Building relationships with inspectors', label: 'Building relationships with inspectors' },
    { key: 'Building relationships with investors', label: 'Building relationships with investors' },
    { key: 'Building relationships with lenders', label: 'Building relationships with lenders' },
    { key: 'Building relationships with other professionals in the industry', label: 'Building relationships with other professionals in the industry' },
    { key: 'Building relationships with property managers', label: 'Building relationships with property managers' },
    { key: 'Building relationships with tenants', label: 'Building relationships with tenants' },
    { key: 'Building systems', label: 'Building systems' },
    { key: 'Business acumen', label: 'Business acumen' },
    { key: 'Business acumen and financial analysis', label: 'Business acumen and financial analysis' },
    { key: 'Business acumen and financial analysis for arts organizations', label: 'Business acumen and financial analysis for arts organizations' },
    { key: 'Business analysis', label: 'Business analysis' },
    { key: 'Business analysis and strategy', label: 'Business analysis and strategy' },
    { key: 'Business case development', label: 'Business case development' },
    { key: 'Business continuity planning', label: 'Business continuity planning' },
    { key: 'Business development', label: 'Business development' },
    { key: 'Business development and marketing in agriculture', label: 'Business development and marketing in agriculture' },
    { key: 'Business development planning', label: 'Business development planning' },
    { key: 'Business development skills', label: 'Business development skills' },
    { key: 'Business intelligence', label: 'Business intelligence' },
    { key: 'Business planning', label: 'Business planning' },
    { key: 'Business process automation', label: 'Business process automation' },
    { key: 'Business process improvement', label: 'Business process improvement' },
    { key: 'Business process management', label: 'Business process management' },
    { key: 'Business process reengineering', label: 'Business process reengineering' },
    { key: 'Business strategy development', label: 'Business strategy development' },
    { key: 'Business valuation', label: 'Business valuation' },
    { key: 'Business writing skills', label: 'Business writing skills' },
    { key: 'CAD', label: 'CAD' },
    { key: 'Camera equipment maintenance', label: 'Camera equipment maintenance' },
    { key: 'Camera equipment operation', label: 'Camera equipment operation' },
    { key: 'Camera operation', label: 'Camera operation' },
    { key: 'Campaign analysis', label: 'Campaign analysis' },
    { key: 'Campaign analysis and optimization', label: 'Campaign analysis and optimization' },
    { key: 'Candidate sourcing', label: 'Candidate sourcing' },
    { key: 'Capacity building', label: 'Capacity building' },
    { key: 'Capacity planning', label: 'Capacity planning' },
    { key: 'Capital budgeting', label: 'Capital budgeting' },
    { key: 'Capital markets', label: 'Capital markets' },
    { key: 'Capital raising', label: 'Capital raising' },
    { key: 'Carbon capture and sequestration technologies', label: 'Carbon capture and sequestration technologies' },
    { key: 'Carbon capture and storage', label: 'Carbon capture and storage' },
    { key: 'Carbon offset project development and management', label: 'Carbon offset project development and management' },
    { key: 'Carbon pricing', label: 'Carbon pricing' },
    { key: 'Career development', label: 'Career development' },
    { key: 'Casting and talent management', label: 'Casting and talent management' },
    { key: 'Celebrity and influencer outreach', label: 'Celebrity and influencer outreach' },
    { key: 'Change management', label: 'Change management' },
    { key: 'Change management and adaptation', label: 'Change management and adaptation' },
    { key: 'Change management for arts organizations', label: 'Change management for arts organizations' },
    { key: 'Chatbot development: Dialogflow, Botpress, and more.', label: 'Chatbot development: Dialogflow, Botpress, and more.' },
    { key: 'Chatbots', label: 'Chatbots' },
    { key: 'Chemical engineering', label: 'Chemical engineering' },
    { key: 'Chemistry', label: 'Chemistry' },
    { key: 'Choreography and dance performance', label: 'Choreography and dance performance' },
    { key: 'Cinematography', label: 'Cinematography' },
    { key: 'Cinematography and film editing', label: 'Cinematography and film editing' },
    { key: 'Civil engineering', label: 'Civil engineering' },
    { key: 'Classical mechanics knowledge and skills', label: 'Classical mechanics knowledge and skills' },
    { key: 'Clean energy and sustainability', label: 'Clean energy and sustainability' },
    { key: 'Client acquisition', label: 'Client acquisition' },
    { key: 'Client management tools and techniques', label: 'Client management tools and techniques' },
    { key: 'Client needs assessment', label: 'Client needs assessment' },
    { key: 'Client relations', label: 'Client relations' },
    { key: 'Client relationship building', label: 'Client relationship building' },
    { key: 'Client relationship management', label: 'Client relationship management' },
    { key: 'Client relationship management software', label: 'Client relationship management software' },
    { key: 'Client retention', label: 'Client retention' },
    { key: 'Climate adaptation', label: 'Climate adaptation' },
    { key: 'Climate change adaptation and mitigation strategies', label: 'Climate change adaptation and mitigation strategies' },
    { key: 'Climate change mitigation', label: 'Climate change mitigation' },
    { key: 'Cloud computing', label: 'Cloud computing' },
    { key: 'Cloud computing: AWS Lambda, Google Cloud Functions, and more.', label: 'Cloud computing: AWS Lambda, Google Cloud Functions, and more.' },
    { key: 'CNC machining and programming', label: 'CNC machining and programming' },
    { key: 'Coaching and mentoring', label: 'Coaching and mentoring' },
    { key: 'Coaching and mentoring for artists and performers', label: 'Coaching and mentoring for artists and performers' },
    { key: 'Coaching skills', label: 'Coaching skills' },
    { key: 'COBIT', label: 'COBIT' },
    { key: 'Code compliance', label: 'Code compliance' },
    { key: 'Collaboration', label: 'Collaboration' },
    { key: 'Collaboration and teamwork', label: 'Collaboration and teamwork' },
    { key: 'Collaboration skills', label: 'Collaboration skills' },
    { key: 'Collaboration tools', label: 'Collaboration tools' },
    { key: 'Color grading', label: 'Color grading' },
    { key: 'Color grading software', label: 'Color grading software' },
    { key: 'Color theory and composition', label: 'Color theory and composition' },
    { key: 'Combustion engineering', label: 'Combustion engineering' },
    { key: 'Commercial design', label: 'Commercial design' },
    { key: 'Commercial law', label: 'Commercial law' },
    { key: 'Commercial real estate experience', label: 'Commercial real estate experience' },
    { key: 'Commodity markets', label: 'Commodity markets' },
    { key: 'Commodity risk management', label: 'Commodity risk management' },
    { key: 'Commodity trading', label: 'Commodity trading' },
    { key: 'Communication', label: 'Communication' },
    { key: 'Communication and interpersonal skills', label: 'Communication and interpersonal skills' },
    { key: 'Communication and media relations', label: 'Communication and media relations' },
    { key: 'Communication skills', label: 'Communication skills' },
    { key: 'Community engagement', label: 'Community engagement' },
    { key: 'Community outreach', label: 'Community outreach' },
    { key: 'Community relations', label: 'Community relations' },
    { key: 'Compensation and rewards management', label: 'Compensation and rewards management' },
    { key: 'Competitive analysis', label: 'Competitive analysis' },
    { key: 'Competitive analysis and benchmarking', label: 'Competitive analysis and benchmarking' },
    { key: 'Competitive intelligence', label: 'Competitive intelligence' },
    { key: 'Competitive intelligence.', label: 'Competitive intelligence.' },
    { key: 'Compliance', label: 'Compliance' },
    { key: 'Compliance management', label: 'Compliance management' },
    { key: 'Compositing software', label: 'Compositing software' },
    { key: 'Compressed air energy storage', label: 'Compressed air energy storage' },
    { key: 'Computational physics skills', label: 'Computational physics skills' },
    { key: 'Computer literacy', label: 'Computer literacy' },
    { key: 'Computer programming', label: 'Computer programming' },
    { key: 'Computer skills', label: 'Computer skills' },
    { key: 'Concept art', label: 'Concept art' },
    { key: 'Conceptual design', label: 'Conceptual design' },
    { key: 'Conceptual thinking', label: 'Conceptual thinking' },
    { key: 'Conceptual thinking and ideation', label: 'Conceptual thinking and ideation' },
    { key: 'Condensed matter physics knowledge and skills', label: 'Condensed matter physics knowledge and skills' },
    { key: 'Condition monitoring', label: 'Condition monitoring' },
    { key: 'Conducting and musical arrangement', label: 'Conducting and musical arrangement' },
    { key: 'Configuration management', label: 'Configuration management' },
    { key: 'Conflict management', label: 'Conflict management' },
    { key: 'Conflict resolution', label: 'Conflict resolution' },
    { key: 'Conflict resolution and mediation', label: 'Conflict resolution and mediation' },
    { key: 'Conflict resolution and negotiation', label: 'Conflict resolution and negotiation' },
    { key: 'Conflict resolution skills', label: 'Conflict resolution skills' },
    { key: 'Constitutional law', label: 'Constitutional law' },
    { key: 'Construction administration', label: 'Construction administration' },
    { key: 'Construction documents', label: 'Construction documents' },
    { key: 'Construction management', label: 'Construction management' },
    { key: 'Construction materials and methods', label: 'Construction materials and methods' },
    { key: 'Construction scheduling', label: 'Construction scheduling' },
    { key: 'Consumer behavior analysis', label: 'Consumer behavior analysis' },
    { key: 'Consumer research and analysis', label: 'Consumer research and analysis' },
    { key: 'Contaminant fate and transport modeling', label: 'Contaminant fate and transport modeling' },
    { key: 'Content creation and production', label: 'Content creation and production' },
    { key: 'Content management systems', label: 'Content management systems' },
    { key: 'Content management systems: WordPress, Drupal, and more.', label: 'Content management systems: WordPress, Drupal, and more.' },
    { key: 'Content marketing', label: 'Content marketing' },
    { key: 'Content marketing skills', label: 'Content marketing skills' },
    { key: 'Content strategy', label: 'Content strategy' },
    { key: 'Continuous improvement', label: 'Continuous improvement' },
    { key: 'Continuous learning and self-improvement', label: 'Continuous learning and self-improvement' },
    { key: 'Continuous learning and self-improvement.', label: 'Continuous learning and self-improvement.' },
    { key: 'Contract administration', label: 'Contract administration' },
    { key: 'Contract drafting', label: 'Contract drafting' },
    { key: 'Contract drafting and negotiation', label: 'Contract drafting and negotiation' },
    { key: 'Contract management', label: 'Contract management' },
    { key: 'Contract management and administration', label: 'Contract management and administration' },
    { key: 'Contract management software', label: 'Contract management software' },
    { key: 'Contract negotiation', label: 'Contract negotiation' },
    { key: 'Contract negotiation and drafting', label: 'Contract negotiation and drafting' },
    { key: 'Contract negotiation and management', label: 'Contract negotiation and management' },
    { key: 'Contract negotiation and management for artists and performers', label: 'Contract negotiation and management for artists and performers' },
    { key: 'Control theory', label: 'Control theory' },
    { key: 'Controls engineering', label: 'Controls engineering' },
    { key: 'Copyright law', label: 'Copyright law' },
    { key: 'Copywriting', label: 'Copywriting' },
    { key: 'Copywriting and content creation', label: 'Copywriting and content creation' },
    { key: 'Corporate communications', label: 'Corporate communications' },
    { key: 'Corporate finance', label: 'Corporate finance' },
    { key: 'Corporate governance', label: 'Corporate governance' },
    { key: 'Corporate law', label: 'Corporate law' },
    { key: 'Corporate social responsibility', label: 'Corporate social responsibility' },
    { key: 'Corporate social responsibility.', label: 'Corporate social responsibility.' },
    { key: 'Corporate strategy', label: 'Corporate strategy' },
    { key: 'Corrosion engineering', label: 'Corrosion engineering' },
    { key: 'Cosmology knowledge and skills', label: 'Cosmology knowledge and skills' },
    { key: 'Cost accounting and financial analysis', label: 'Cost accounting and financial analysis' },
    { key: 'Cost analysis', label: 'Cost analysis' },
    { key: 'Cost control', label: 'Cost control' },
    { key: 'Cost control and management', label: 'Cost control and management' },
    { key: 'Cost estimating', label: 'Cost estimating' },
    { key: 'Cost estimation and pricing strategies', label: 'Cost estimation and pricing strategies' },
    { key: 'Cost optimization', label: 'Cost optimization' },
    { key: 'Cost reduction', label: 'Cost reduction' },
    { key: 'Cost-saving strategies', label: 'Cost-saving strategies' },
    { key: 'Costume and makeup design', label: 'Costume and makeup design' },
    { key: 'Costume design', label: 'Costume design' },
    { key: 'Costume design and fashion knowledge', label: 'Costume design and fashion knowledge' },
    { key: 'Courtroom advocacy', label: 'Courtroom advocacy' },
    { key: 'Creative direction and ideation', label: 'Creative direction and ideation' },
    { key: 'Creative problem solving', label: 'Creative problem solving' },
    { key: 'Creative problem-solving and innovation', label: 'Creative problem-solving and innovation' },
    { key: 'Creative thinking', label: 'Creative thinking' },
    { key: 'Creative vision', label: 'Creative vision' },
    { key: 'Creative vision and storytelling ability', label: 'Creative vision and storytelling ability' },
    { key: 'Creativity', label: 'Creativity' },
    { key: 'Creativity and ideation', label: 'Creativity and ideation' },
    { key: 'Creativity and imagination', label: 'Creativity and imagination' },
    { key: 'Creativity and innovation', label: 'Creativity and innovation' },
    { key: 'Creativity and originality', label: 'Creativity and originality' },
    { key: 'Credit analysis', label: 'Credit analysis' },
    { key: 'Credit risk management', label: 'Credit risk management' },
    { key: 'Crisis management', label: 'Crisis management' },
    { key: 'Crisis management and communication', label: 'Crisis management and communication' },
    { key: 'Crisis management and communication for arts events', label: 'Crisis management and communication for arts events' },
    { key: 'Crisis management and decision making under pressure', label: 'Crisis management and decision making under pressure' },
    { key: 'Critical thinking', label: 'Critical thinking' },
    { key: 'Critical thinking and analysis', label: 'Critical thinking and analysis' },
    { key: 'Critical thinking and problem-solving abilities', label: 'Critical thinking and problem-solving abilities' },
    { key: 'Critical thinking skills', label: 'Critical thinking skills' },
    { key: 'CRM systems', label: 'CRM systems' },
    { key: 'Crop management and production techniques', label: 'Crop management and production techniques' },
    { key: 'Crop rotation and intercropping techniques', label: 'Crop rotation and intercropping techniques' },
    { key: 'Cross-cultural communication', label: 'Cross-cultural communication' },
    { key: 'Cross-cultural understanding', label: 'Cross-cultural understanding' },
    { key: 'Cross-functional collaboration', label: 'Cross-functional collaboration' },
    { key: 'Cross-functional collaboration and teamwork', label: 'Cross-functional collaboration and teamwork' },
    { key: 'Cross-functional team management', label: 'Cross-functional team management' },
    { key: 'Cryptocurrency', label: 'Cryptocurrency' },
    { key: 'Culinary skills and knowledge', label: 'Culinary skills and knowledge' },
    { key: 'Cultural awareness and sensitivity', label: 'Cultural awareness and sensitivity' },
    { key: 'Cultural competence', label: 'Cultural competence' },
    { key: 'Cultural sensitivity', label: 'Cultural sensitivity' },
    { key: 'Cultural sensitivity and awareness', label: 'Cultural sensitivity and awareness' },
    { key: 'Culture transformation', label: 'Culture transformation' },
    { key: 'Curatorial studies', label: 'Curatorial studies' },
    { key: 'Customer experience (CX) design', label: 'Customer experience (CX) design' },
    { key: 'Customer experience and journey mapping', label: 'Customer experience and journey mapping' },
    { key: 'Customer experience management', label: 'Customer experience management' },
    { key: 'Customer experience management.', label: 'Customer experience management.' },
    { key: 'Customer feedback and survey analysis', label: 'Customer feedback and survey analysis' },
    { key: 'Customer journey mapping', label: 'Customer journey mapping' },
    { key: 'Customer needs assessment', label: 'Customer needs assessment' },
    { key: 'Customer profiling', label: 'Customer profiling' },
    { key: 'Customer relationship management', label: 'Customer relationship management' },
    { key: 'Customer relationship management (CRM)', label: 'Customer relationship management (CRM)' },
    { key: 'Customer relationship management (CRM) skills', label: 'Customer relationship management (CRM) skills' },
    { key: 'Customer retention', label: 'Customer retention' },
    { key: 'Customer retention and loyalty programs', label: 'Customer retention and loyalty programs' },
    { key: 'Customer segmentation and targeting', label: 'Customer segmentation and targeting' },
    { key: 'Customer service', label: 'Customer service' },
    { key: 'Customer service and satisfaction', label: 'Customer service and satisfaction' },
    { key: 'Customer service skills', label: 'Customer service skills' },
    { key: 'Customer service strategy and execution', label: 'Customer service strategy and execution' },
    { key: 'Cybercrime investigation', label: 'Cybercrime investigation' },
    { key: 'Cybersecurity', label: 'Cybersecurity' },
    { key: 'Cybersecurity and data privacy law', label: 'Cybersecurity and data privacy law' },
    { key: 'Data acquisition and analysis skills', label: 'Data acquisition and analysis skills' },
    { key: 'Data analysis', label: 'Data analysis' },
    { key: 'Data analysis and interpretation', label: 'Data analysis and interpretation' },
    { key: 'Data analysis and management', label: 'Data analysis and management' },
    { key: 'Data analysis and visualization', label: 'Data analysis and visualization' },
    { key: 'Data analysis skills', label: 'Data analysis skills' },
    { key: 'Data analysis: Pandas, NumPy, and more.', label: 'Data analysis: Pandas, NumPy, and more.' },
    { key: 'Data analytics and visualization', label: 'Data analytics and visualization' },
    { key: 'Data architecture', label: 'Data architecture' },
    { key: 'Data governance', label: 'Data governance' },
    { key: 'Data management', label: 'Data management' },
    { key: 'Data modeling', label: 'Data modeling' },
    { key: 'Data privacy', label: 'Data privacy' },
    { key: 'Data privacy and protection', label: 'Data privacy and protection' },
    { key: 'Data privacy and security', label: 'Data privacy and security' },
    { key: 'Data science and analytics', label: 'Data science and analytics' },
    { key: 'Data security', label: 'Data security' },
    { key: 'Data visualization', label: 'Data visualization' },
    { key: 'Data visualization skills', label: 'Data visualization skills' },
    { key: 'Data visualization: D3.js, Tableau, and more.', label: 'Data visualization: D3.js, Tableau, and more.' },
    { key: 'Data warehousing', label: 'Data warehousing' },
    { key: 'Data-driven insights', label: 'Data-driven insights' },
    { key: 'Data-driven insights for arts organizations', label: 'Data-driven insights for arts organizations' },
    { key: 'Database design', label: 'Database design' },
    { key: 'Database design and management', label: 'Database design and management' },
    { key: 'Database management', label: 'Database management' },
    { key: 'Database management: MySQL, PostgreSQL, MongoDB, Oracle, SQL Server, and more.', label: 'Database management: MySQL, PostgreSQL, MongoDB, Oracle, SQL Server, and more.' },
    { key: 'Decision making', label: 'Decision making' },
    { key: 'Decision-making', label: 'Decision-making' },
    { key: 'Decision-making and problem-solving', label: 'Decision-making and problem-solving' },
    { key: 'Decision-making and problem-solving abilities', label: 'Decision-making and problem-solving abilities' },
    { key: 'Decision-making skills', label: 'Decision-making skills' },
    { key: 'Delegation and empowerment', label: 'Delegation and empowerment' },
    { key: 'Demand forecasting', label: 'Demand forecasting' },
    { key: 'Demand response', label: 'Demand response' },
    { key: 'Demographic analysis', label: 'Demographic analysis' },
    { key: 'Derivatives', label: 'Derivatives' },
    { key: 'Design development', label: 'Design development' },
    { key: 'Design management', label: 'Design management' },
    { key: 'Design-build delivery', label: 'Design-build delivery' },
    { key: 'Detail orientation', label: 'Detail orientation' },
    { key: 'Detail-oriented', label: 'Detail-oriented' },
    { key: 'DevOps', label: 'DevOps' },
    { key: 'DevOps: AWS, Azure, Google Cloud, Docker, Kubernetes, Jenkins, Git, and more.', label: 'DevOps: AWS, Azure, Google Cloud, Docker, Kubernetes, Jenkins, Git, and more.' },
    { key: 'Dialogue editing', label: 'Dialogue editing' },
    { key: 'Dialogue replacement', label: 'Dialogue replacement' },
    { key: 'Digital and traditional art skills', label: 'Digital and traditional art skills' },
    { key: 'Digital audio workstations (DAWs)', label: 'Digital audio workstations (DAWs)' },
    { key: 'Digital fabrication', label: 'Digital fabrication' },
    { key: 'Digital marketing', label: 'Digital marketing' },
    { key: 'Digital marketing and advertising', label: 'Digital marketing and advertising' },
    { key: 'Digital marketing strategy development', label: 'Digital marketing strategy development' },
    { key: 'Digital modeling', label: 'Digital modeling' },
    { key: 'Digital transformation', label: 'Digital transformation' },
    { key: 'Digital transformation and technology adoption', label: 'Digital transformation and technology adoption' },
    { key: 'Diplomacy', label: 'Diplomacy' },
    { key: 'Directing', label: 'Directing' },
    { key: 'Directing and production experience', label: 'Directing and production experience' },
    { key: 'Directing and stagecraft', label: 'Directing and stagecraft' },
    { key: 'Disaster recovery', label: 'Disaster recovery' },
    { key: 'Disaster recovery planning', label: 'Disaster recovery planning' },
    { key: 'Disaster relief design', label: 'Disaster relief design' },
    { key: 'Distributed energy resource management systems.', label: 'Distributed energy resource management systems.' },
    { key: 'Distribution and content delivery', label: 'Distribution and content delivery' },
    { key: 'Distribution management', label: 'Distribution management' },
    { key: 'Distribution system operations', label: 'Distribution system operations' },
    { key: 'Diversity and inclusion', label: 'Diversity and inclusion' },
    { key: 'Diversity, equity, and inclusion', label: 'Diversity, equity, and inclusion' },
    { key: 'Document management software', label: 'Document management software' },
    { key: 'Document management systems', label: 'Document management systems' },
    { key: 'Downstream marketing', label: 'Downstream marketing' },
    { key: 'Downstream operations', label: 'Downstream operations' },
    { key: 'Drawing and sketching', label: 'Drawing and sketching' },
    { key: 'Drilling engineering', label: 'Drilling engineering' },
    { key: 'Drilling operations', label: 'Drilling operations' },
    { key: 'Drones', label: 'Drones' },
    { key: 'Due diligence', label: 'Due diligence' },
    { key: 'Due diligence and valuation', label: 'Due diligence and valuation' },
    { key: 'E-billing software', label: 'E-billing software' },
    { key: 'E-commerce', label: 'E-commerce' },
    { key: 'E-commerce development: Shopify, WooCommerce, and more.', label: 'E-commerce development: Shopify, WooCommerce, and more.' },
    { key: 'E-commerce website development', label: 'E-commerce website development' },
    { key: 'E-commerce.', label: 'E-commerce.' },
    { key: 'Ecological restoration and rehabilitation', label: 'Ecological restoration and rehabilitation' },
    { key: 'Econometrics', label: 'Econometrics' },
    { key: 'Economic development', label: 'Economic development' },
    { key: 'Ecosystem services valuation', label: 'Ecosystem services valuation' },
    { key: 'eDiscovery', label: 'eDiscovery' },
    { key: 'Editing', label: 'Editing' },
    { key: 'Editing and post-production skills', label: 'Editing and post-production skills' },
    { key: 'Education policy', label: 'Education policy' },
    { key: 'Electric vehicle infrastructure', label: 'Electric vehicle infrastructure' },
    { key: 'Electrical engineering', label: 'Electrical engineering' },
    { key: 'Electrical power quality', label: 'Electrical power quality' },
    { key: 'Electrical safety', label: 'Electrical safety' },
    { key: 'Electrical system design', label: 'Electrical system design' },
    { key: 'Electromagnetism knowledge and skills', label: 'Electromagnetism knowledge and skills' },
    { key: 'Electron microscopy knowledge and skills', label: 'Electron microscopy knowledge and skills' },
    { key: 'Email marketing', label: 'Email marketing' },
    { key: 'Email marketing integration: Mailchimp, Constant Contact, and more.', label: 'Email marketing integration: Mailchimp, Constant Contact, and more.' },
    { key: 'Email marketing skills', label: 'Email marketing skills' },
    { key: 'Emergency management', label: 'Emergency management' },
    { key: 'Emergency response', label: 'Emergency response' },
    { key: 'Emergency response and preparedness', label: 'Emergency response and preparedness' },
    { key: 'Emissions trading', label: 'Emissions trading' },
    { key: 'Emotional intelligence', label: 'Emotional intelligence' },
    { key: 'Emotional intelligence and empathy', label: 'Emotional intelligence and empathy' },
    { key: 'Empathy', label: 'Empathy' },
    { key: 'Employee engagement', label: 'Employee engagement' },
    { key: 'Employee engagement and motivation', label: 'Employee engagement and motivation' },
    { key: 'Employee relations', label: 'Employee relations' },
    { key: 'Employee retention', label: 'Employee retention' },
    { key: 'Employee satisfaction', label: 'Employee satisfaction' },
    { key: 'Employer branding', label: 'Employer branding' },
    { key: 'Employer branding content creation', label: 'Employer branding content creation' },
    { key: 'Employer value proposition (EVP) development', label: 'Employer value proposition (EVP) development' },
    { key: 'Employment law', label: 'Employment law' },
    { key: 'Energy audits', label: 'Energy audits' },
    { key: 'Energy benchmarking', label: 'Energy benchmarking' },
    { key: 'Energy economics', label: 'Energy economics' },
    { key: 'Energy efficiency', label: 'Energy efficiency' },
    { key: 'Energy finance', label: 'Energy finance' },
    { key: 'Energy law', label: 'Energy law' },
    { key: 'Energy management', label: 'Energy management' },
    { key: 'Energy management systems', label: 'Energy management systems' },
    { key: 'Energy markets', label: 'Energy markets' },
    { key: 'Energy modeling', label: 'Energy modeling' },
    { key: 'Energy policy', label: 'Energy policy' },
    { key: 'Energy risk management', label: 'Energy risk management' },
    { key: 'Energy storage', label: 'Energy storage' },
    { key: 'Energy storage systems', label: 'Energy storage systems' },
    { key: 'Energy storage technologies', label: 'Energy storage technologies' },
    { key: 'Energy trading', label: 'Energy trading' },
    { key: 'Energy trading platforms', label: 'Energy trading platforms' },
    { key: 'Engineering design', label: 'Engineering design' },
    { key: 'Enterprise architecture', label: 'Enterprise architecture' },
    { key: 'Enterprise risk management', label: 'Enterprise risk management' },
    { key: 'Entrepreneurship', label: 'Entrepreneurship' },
    { key: 'Entrepreneurship and intrapreneurship', label: 'Entrepreneurship and intrapreneurship' },
    { key: 'Environmental compliance', label: 'Environmental compliance' },
    { key: 'Environmental compliance auditing', label: 'Environmental compliance auditing' },
    { key: 'Environmental data management and analysis', label: 'Environmental data management and analysis' },
    { key: 'Environmental due diligence assessments', label: 'Environmental due diligence assessments' },
    { key: 'Environmental engineering', label: 'Environmental engineering' },
    { key: 'Environmental health and safety management', label: 'Environmental health and safety management' },
    { key: 'Environmental health and safety training', label: 'Environmental health and safety training' },
    { key: 'Environmental impact assessment', label: 'Environmental impact assessment' },
    { key: 'Environmental impact assessment and mitigation', label: 'Environmental impact assessment and mitigation' },
    { key: 'Environmental impact statement preparation', label: 'Environmental impact statement preparation' },
    { key: 'Environmental law', label: 'Environmental law' },
    { key: 'Environmental management', label: 'Environmental management' },
    { key: 'Environmental monitoring and data analysis', label: 'Environmental monitoring and data analysis' },
    { key: 'Environmental permitting and compliance', label: 'Environmental permitting and compliance' },
    { key: 'Environmental physics knowledge and skills', label: 'Environmental physics knowledge and skills' },
    { key: 'Environmental policy', label: 'Environmental policy' },
    { key: 'Environmental risk assessment and management', label: 'Environmental risk assessment and management' },
    { key: 'Environmental sustainability', label: 'Environmental sustainability' },
    { key: 'Environmental sustainability and energy management', label: 'Environmental sustainability and energy management' },
    { key: 'Environmental sustainability practices in agriculture', label: 'Environmental sustainability practices in agriculture' },
    { key: 'Environmental, social, and governance (ESG) reporting', label: 'Environmental, social, and governance (ESG) reporting' },
    { key: 'Equity research', label: 'Equity research' },
    { key: 'Ergonomics and safety engineering', label: 'Ergonomics and safety engineering' },
    { key: 'ERP systems', label: 'ERP systems' },
    { key: 'Ethical leadership.', label: 'Ethical leadership.' },
    { key: 'Ethics and compliance', label: 'Ethics and compliance' },
    { key: 'Ethics and corporate social responsibility (CSR)', label: 'Ethics and corporate social responsibility (CSR)' },
    { key: 'Ethics and integrity', label: 'Ethics and integrity' },
    { key: 'Ethics and professional responsibility', label: 'Ethics and professional responsibility' },
    { key: 'Evaluation and monitoring', label: 'Evaluation and monitoring' },
    { key: 'Evaluation skills', label: 'Evaluation skills' },
    { key: 'Event coordination and management', label: 'Event coordination and management' },
    { key: 'Event management', label: 'Event management' },
    { key: 'Event planning', label: 'Event planning' },
    { key: 'Event planning and management', label: 'Event planning and management' },
    { key: 'Event planning and management for arts events', label: 'Event planning and management for arts events' },
    { key: 'Event planning and production', label: 'Event planning and production' },
    { key: 'Event ticketing and box office management', label: 'Event ticketing and box office management' },
    { key: 'Excellent written and verbal communication skills', label: 'Excellent written and verbal communication skills' },
    { key: 'Executive coaching', label: 'Executive coaching' },
    { key: 'Experience design', label: 'Experience design' },
    { key: 'Experience design for arts events', label: 'Experience design for arts events' },
    { key: 'Experience with affiliate marketing programs', label: 'Experience with affiliate marketing programs' },
    { key: 'Experience with chatbot and voice assistant optimization', label: 'Experience with chatbot and voice assistant optimization' },
    { key: 'Experience with customer journey mapping and optimization', label: 'Experience with customer journey mapping and optimization' },
    { key: 'Experience with customer relationship management (CRM) systems', label: 'Experience with customer relationship management (CRM) systems' },
    { key: 'Experience with email marketing and lead nurturing campaigns', label: 'Experience with email marketing and lead nurturing campaigns' },
    { key: 'Experience with gamification and interactive content development', label: 'Experience with gamification and interactive content development' },
    { key: 'Experience with influencer outreach and partnerships', label: 'Experience with influencer outreach and partnerships' },
    { key: 'Experience with mobile app marketing and app store optimization (ASO)', label: 'Experience with mobile app marketing and app store optimization (ASO)' },
    { key: 'Experience with personalization and dynamic content delivery', label: 'Experience with personalization and dynamic content delivery' },
    { key: 'Experience with public relations and media relations', label: 'Experience with public relations and media relations' },
    { key: 'Experience with video production and editing', label: 'Experience with video production and editing' },
    { key: 'Experiential marketing', label: 'Experiential marketing' },
    { key: 'Experimental design skills', label: 'Experimental design skills' },
    { key: 'Expertise in data analysis and visualization tools such as Google Analytics or Tableau', label: 'Expertise in data analysis and visualization tools such as Google Analytics or Tableau' },
    { key: 'Expertise in data privacy and security regulations', label: 'Expertise in data privacy and security regulations' },
    { key: 'Expertise in digital analytics and attribution modeling', label: 'Expertise in digital analytics and attribution modeling' },
    { key: 'Expertise in digital product development and management', label: 'Expertise in digital product development and management' },
    { key: 'Expertise in digital signage and out-of-home advertising', label: 'Expertise in digital signage and out-of-home advertising' },
    { key: 'Expertise in paid advertising platforms such as Google Ads, Facebook Ads, or LinkedIn Ads', label: 'Expertise in paid advertising platforms such as Google Ads, Facebook Ads, or LinkedIn Ads' },
    { key: 'Expertise in social media advertising, including Facebook, Instagram, Twitter, and LinkedIn', label: 'Expertise in social media advertising, including Facebook, Instagram, Twitter, and LinkedIn' },
    { key: 'Expertise in user-generated content (UGC) campaigns', label: 'Expertise in user-generated content (UGC) campaigns' },
    { key: 'Expertise in video advertising, including YouTube and OTT/CTV platforms', label: 'Expertise in video advertising, including YouTube and OTT/CTV platforms' },
    { key: 'Export control and compliance', label: 'Export control and compliance' },
    { key: 'External audit', label: 'External audit' },
    { key: 'Facilitation', label: 'Facilitation' },
    { key: 'Facilitation skills', label: 'Facilitation skills' },
    { key: 'Failure analysis', label: 'Failure analysis' },
    { key: 'Failure analysis and metallurgy', label: 'Failure analysis and metallurgy' },
    { key: 'Familiarity with account-based marketing (ABM) strategies', label: 'Familiarity with account-based marketing (ABM) strategies' },
    { key: 'Familiarity with content localization and internationalization strategies', label: 'Familiarity with content localization and internationalization strategies' },
    { key: 'Familiarity with content management systems (CMS)', label: 'Familiarity with content management systems (CMS)' },
    { key: 'Familiarity with content management systems (CMS) such as WordPress or Drupal', label: 'Familiarity with content management systems (CMS) such as WordPress or Drupal' },
    { key: 'Familiarity with content marketing and inbound marketing strategies', label: 'Familiarity with content marketing and inbound marketing strategies' },
    { key: 'Familiarity with marketing automation platforms such as HubSpot or Marketo', label: 'Familiarity with marketing automation platforms such as HubSpot or Marketo' },
    { key: 'Familiarity with online reputation management strategies', label: 'Familiarity with online reputation management strategies' },
    { key: 'Familiarity with programmatic advertising and real-time bidding (RTB) platforms', label: 'Familiarity with programmatic advertising and real-time bidding (RTB) platforms' },
    { key: 'Familiarity with SEO best practices', label: 'Familiarity with SEO best practices' },
    { key: 'Familiarity with social media listening and monitoring tools', label: 'Familiarity with social media listening and monitoring tools' },
    { key: 'Familiarity with virtual and augmented reality technologies', label: 'Familiarity with virtual and augmented reality technologies' },
    { key: 'Familiarity with virtual event and webinar production', label: 'Familiarity with virtual event and webinar production' },
    { key: 'Family law', label: 'Family law' },
    { key: 'Farm business planning and management', label: 'Farm business planning and management' },
    { key: 'Feasibility studies', label: 'Feasibility studies' },
    { key: 'Fiber optics', label: 'Fiber optics' },
    { key: 'Final Cut Pro', label: 'Final Cut Pro' },
    { key: 'Financial advising', label: 'Financial advising' },
    { key: 'Financial analysis', label: 'Financial analysis' },
    { key: 'Financial analysis and modeling', label: 'Financial analysis and modeling' },
    { key: 'Financial analysis software', label: 'Financial analysis software' },
    { key: 'Financial coaching', label: 'Financial coaching' },
    { key: 'Financial consulting', label: 'Financial consulting' },
    { key: 'Financial control', label: 'Financial control' },
    { key: 'Financial crime prevention', label: 'Financial crime prevention' },
    { key: 'Financial data analytics', label: 'Financial data analytics' },
    { key: 'Financial data management', label: 'Financial data management' },
    { key: 'Financial due diligence', label: 'Financial due diligence' },
    { key: 'Financial education', label: 'Financial education' },
    { key: 'Financial management', label: 'Financial management' },
    { key: 'Financial management and budgeting', label: 'Financial management and budgeting' },
    { key: 'Financial modeling', label: 'Financial modeling' },
    { key: 'Financial modeling and forecasting', label: 'Financial modeling and forecasting' },
    { key: 'Financial planning', label: 'Financial planning' },
    { key: 'Financial planning and analysis', label: 'Financial planning and analysis' },
    { key: 'Financial planning and forecasting', label: 'Financial planning and forecasting' },
    { key: 'Financial regulation', label: 'Financial regulation' },
    { key: 'Financial reporting', label: 'Financial reporting' },
    { key: 'Financial research', label: 'Financial research' },
    { key: 'Financial risk assessment', label: 'Financial risk assessment' },
    { key: 'Financial services', label: 'Financial services' },
    { key: 'Financial software', label: 'Financial software' },
    { key: 'Financial statement analysis', label: 'Financial statement analysis' },
    { key: 'Financial systems', label: 'Financial systems' },
    { key: 'Financial technology', label: 'Financial technology' },
    { key: 'Financial writing', label: 'Financial writing' },
    { key: 'Fire protection and prevention', label: 'Fire protection and prevention' },
    { key: 'Firefighting', label: 'Firefighting' },
    { key: 'First aid', label: 'First aid' },
    { key: 'Fish passage and stream restoration', label: 'Fish passage and stream restoration' },
    { key: 'Fisheries biology and ecology', label: 'Fisheries biology and ecology' },
    { key: 'Fisheries management and conservation', label: 'Fisheries management and conservation' },
    { key: 'Fixed income analysis', label: 'Fixed income analysis' },
    { key: 'Fleet management and vehicle maintenance', label: 'Fleet management and vehicle maintenance' },
    { key: 'Flexibility', label: 'Flexibility' },
    { key: 'Flexibility and adaptability', label: 'Flexibility and adaptability' },
    { key: 'Flexibility and adaptability to changing trends and styles', label: 'Flexibility and adaptability to changing trends and styles' },
    { key: 'Flow battery energy storage', label: 'Flow battery energy storage' },
    { key: 'Fluid dynamics', label: 'Fluid dynamics' },
    { key: 'Fluid mechanics knowledge and skills', label: 'Fluid mechanics knowledge and skills' },
    { key: 'Flywheel energy storage', label: 'Flywheel energy storage' },
    { key: 'Foley artist skills', label: 'Foley artist skills' },
    { key: 'Foley recording equipment', label: 'Foley recording equipment' },
    { key: 'Foley recording software', label: 'Foley recording software' },
    { key: 'Foley recording techniques', label: 'Foley recording techniques' },
    { key: 'Foley sound', label: 'Foley sound' },
    { key: 'Foley sound effects creation', label: 'Foley sound effects creation' },
    { key: 'Foley sound effects libraries', label: 'Foley sound effects libraries' },
    { key: 'Food processing and preservation techniques', label: 'Food processing and preservation techniques' },
    { key: 'Food safety and hygiene', label: 'Food safety and hygiene' },
    { key: 'Forecasting', label: 'Forecasting' },
    { key: 'Forecasting and budgeting skills', label: 'Forecasting and budgeting skills' },
    { key: 'Forecasting and predictive analysis', label: 'Forecasting and predictive analysis' },
    { key: 'Forecasting and revenue management', label: 'Forecasting and revenue management' },
    { key: 'Foreign policy', label: 'Foreign policy' },
    { key: 'Forensic science', label: 'Forensic science' },
    { key: 'Forest inventory and monitoring', label: 'Forest inventory and monitoring' },
    { key: 'Forest products and value-added processing', label: 'Forest products and value-added processing' },
    { key: 'Forestry biometrics and inventory techniques', label: 'Forestry biometrics and inventory techniques' },
    { key: 'Forestry management and conservation', label: 'Forestry management and conservation' },
    { key: 'Freight forwarding', label: 'Freight forwarding' },
    { key: 'Front-end development', label: 'Front-end development' },
    { key: 'Front-end web development', label: 'Front-end web development' },
    { key: 'Front-end web development: React, Angular, Vue.js, Bootstrap, Foundation, Material UI, jQuery, SASS, LESS, and more.', label: 'Front-end web development: React, Angular, Vue.js, Bootstrap, Foundation, Material UI, jQuery, SASS, LESS, and more.' },
    { key: 'Full-stack development', label: 'Full-stack development' },
    { key: 'Full-stack web development: MEAN stack, MERN stack, LAMP stack, and more.', label: 'Full-stack web development: MEAN stack, MERN stack, LAMP stack, and more.' },
    { key: 'Fund administration', label: 'Fund administration' },
    { key: 'Fundraising', label: 'Fundraising' },
    { key: 'Fundraising and development for arts organizations', label: 'Fundraising and development for arts organizations' },
    { key: 'Fundraising event planning and execution', label: 'Fundraising event planning and execution' },
    { key: 'Game design and development', label: 'Game design and development' },
    { key: 'Game development: Unity, Unreal Engine, and more.', label: 'Game development: Unity, Unreal Engine, and more.' },
    { key: 'Gaming development', label: 'Gaming development' },
    { key: 'Gas turbines', label: 'Gas turbines' },
    { key: 'GDPR', label: 'GDPR' },
    { key: 'Geographic information systems (GIS) for agriculture', label: 'Geographic information systems (GIS) for agriculture' },
    { key: 'Geomatics and surveying in natural resource industries', label: 'Geomatics and surveying in natural resource industries' },
    { key: 'Geophysics', label: 'Geophysics' },
    { key: 'Geophysics knowledge and skills', label: 'Geophysics knowledge and skills' },
    { key: 'Geospatial analysis', label: 'Geospatial analysis' },
    { key: 'Geotechnical engineering', label: 'Geotechnical engineering' },
    { key: 'Geothermal energy technologies and development', label: 'Geothermal energy technologies and development' },
    { key: 'Geothermal power', label: 'Geothermal power' },
    { key: 'GIS and remote sensing in natural resource management', label: 'GIS and remote sensing in natural resource management' },
    { key: 'Global business acumen', label: 'Global business acumen' },
    { key: 'Global logistics', label: 'Global logistics' },
    { key: 'Global logistics and supply chain management', label: 'Global logistics and supply chain management' },
    { key: 'Goal setting', label: 'Goal setting' },
    { key: 'Goal-setting and planning', label: 'Goal-setting and planning' },
    { key: 'Government contracts', label: 'Government contracts' },
    { key: 'Government operations', label: 'Government operations' },
    { key: 'Grant management', label: 'Grant management' },
    { key: 'Grant writing', label: 'Grant writing' },
    { key: 'Grant writing and fundraising', label: 'Grant writing and fundraising' },
    { key: 'Grant writing and management for arts organizations', label: 'Grant writing and management for arts organizations' },
    { key: 'Graphic design', label: 'Graphic design' },
    { key: 'Graphic design and typography', label: 'Graphic design and typography' },
    { key: 'Green building practices', label: 'Green building practices' },
    { key: 'Greenhouse gas inventory and reporting', label: 'Greenhouse gas inventory and reporting' },
    { key: 'Grid management software', label: 'Grid management software' },
    { key: 'Grip and electric work', label: 'Grip and electric work' },
    { key: 'Hand drafting', label: 'Hand drafting' },
    { key: 'Hazardous materials handling', label: 'Hazardous materials handling' },
    { key: 'Hazardous waste management and disposal', label: 'Hazardous waste management and disposal' },
    { key: 'Health and safety', label: 'Health and safety' },
    { key: 'Health and safety compliance', label: 'Health and safety compliance' },
    { key: 'Health and safety management', label: 'Health and safety management' },
    { key: 'Health policy', label: 'Health policy' },
    { key: 'Healthcare law', label: 'Healthcare law' },
    { key: 'Heat transfer and thermodynamics', label: 'Heat transfer and thermodynamics' },
    { key: 'Hedge fund management', label: 'Hedge fund management' },
    { key: 'High voltage engineering', label: 'High voltage engineering' },
    { key: 'High-performance computing skills', label: 'High-performance computing skills' },
    { key: 'HIPAA', label: 'HIPAA' },
    { key: 'HMI and SCADA systems', label: 'HMI and SCADA systems' },
    { key: 'Homeland security', label: 'Homeland security' },
    { key: 'Horticulture and greenhouse management', label: 'Horticulture and greenhouse management' },
    { key: 'HR analytics', label: 'HR analytics' },
    { key: 'HR compliance', label: 'HR compliance' },
    { key: 'Human factors engineering', label: 'Human factors engineering' },
    { key: 'Human resource management', label: 'Human resource management' },
    { key: 'Human resource management and recruitment', label: 'Human resource management and recruitment' },
    { key: 'Human resources management', label: 'Human resources management' },
    { key: 'Human rights', label: 'Human rights' },
    { key: 'Human-centered design', label: 'Human-centered design' },
    { key: 'HVAC engineering', label: 'HVAC engineering' },
    { key: 'Hydroelectric power', label: 'Hydroelectric power' },
    { key: 'Hydroelectric power generation and management', label: 'Hydroelectric power generation and management' },
    { key: 'Illustration and comics', label: 'Illustration and comics' },
    { key: 'Immigration law', label: 'Immigration law' },
    { key: 'Immigration policy', label: 'Immigration policy' },
    { key: 'Incident management', label: 'Incident management' },
    { key: 'Incident response', label: 'Incident response' },
    { key: 'Indigenous peoples engagement and consultation', label: 'Indigenous peoples engagement and consultation' },
    { key: 'Industrial automation and robotics', label: 'Industrial automation and robotics' },
    { key: 'Industrial chemistry', label: 'Industrial chemistry' },
    { key: 'Industrial design', label: 'Industrial design' },
    { key: 'Industrial design and prototyping', label: 'Industrial design and prototyping' },
    { key: 'Industrial engineering', label: 'Industrial engineering' },
    { key: 'Industrial hygiene and exposure assessment', label: 'Industrial hygiene and exposure assessment' },
    { key: 'Industry analysis', label: 'Industry analysis' },
    { key: 'Industry knowledge', label: 'Industry knowledge' },
    { key: 'Industry knowledge and expertise', label: 'Industry knowledge and expertise' },
    { key: 'Influence and persuasion', label: 'Influence and persuasion' },
    { key: 'Influencer marketing', label: 'Influencer marketing' },
    { key: 'Influencing and persuasion', label: 'Influencing and persuasion' },
    { key: 'Information architecture', label: 'Information architecture' },
    { key: 'Information management', label: 'Information management' },
    { key: 'Information technology', label: 'Information technology' },
    { key: 'Information technology and systems integration', label: 'Information technology and systems integration' },
    { key: 'Infrastructure planning and development', label: 'Infrastructure planning and development' },
    { key: 'Innovation', label: 'Innovation' },
    { key: 'Innovation and creativity', label: 'Innovation and creativity' },
    { key: 'Innovation and experimentation', label: 'Innovation and experimentation' },
    { key: 'Innovation and new technology evaluation', label: 'Innovation and new technology evaluation' },
    { key: 'Innovation management', label: 'Innovation management' },
    { key: 'Inspection', label: 'Inspection' },
    { key: 'Inspection and testing', label: 'Inspection and testing' },
    { key: 'Instrumental or vocal proficiency', label: 'Instrumental or vocal proficiency' },
    { key: 'Instrumentation and control engineering', label: 'Instrumentation and control engineering' },
    { key: 'Instrumentation and control systems', label: 'Instrumentation and control systems' },
    { key: 'Insurance law', label: 'Insurance law' },
    { key: 'Intellectual property', label: 'Intellectual property' },
    { key: 'Intellectual property (IP) protection', label: 'Intellectual property (IP) protection' },
    { key: 'Intellectual property (IP) protection for the arts', label: 'Intellectual property (IP) protection for the arts' },
    { key: 'Intellectual property law', label: 'Intellectual property law' },
    { key: 'Intellectual property management', label: 'Intellectual property management' },
    { key: 'Intellectual property protection', label: 'Intellectual property protection' },
    { key: 'Intellectual property rights management', label: 'Intellectual property rights management' },
    { key: 'Intelligence analysis', label: 'Intelligence analysis' },
    { key: 'Interior design', label: 'Interior design' },
    { key: 'Internal audit', label: 'Internal audit' },
    { key: 'International arts and culture', label: 'International arts and culture' },
    { key: 'International business', label: 'International business' },
    { key: 'International business and cultural awareness', label: 'International business and cultural awareness' },
    { key: 'International distribution and licensing', label: 'International distribution and licensing' },
    { key: 'International finance and taxation', label: 'International finance and taxation' },
    { key: 'International marketing and global strategy', label: 'International marketing and global strategy' },
    { key: 'International marketing and localization', label: 'International marketing and localization' },
    { key: 'International trade', label: 'International trade' },
    { key: 'International trade and commerce', label: 'International trade and commerce' },
    { key: 'International trade and commerce.', label: 'International trade and commerce.' },
    { key: 'International trade and customs', label: 'International trade and customs' },
    { key: 'International trade and customs regulations', label: 'International trade and customs regulations' },
    { key: 'Internet of Things', label: 'Internet of Things' },
    { key: 'Internet of Things (IoT)', label: 'Internet of Things (IoT)' },
    { key: 'Internet of Things (IoT) technology', label: 'Internet of Things (IoT) technology' },
    { key: 'Interpersonal communication', label: 'Interpersonal communication' },
    { key: 'Interpersonal communication and networking', label: 'Interpersonal communication and networking' },
    { key: 'Interpersonal skills', label: 'Interpersonal skills' },
    { key: 'Interviewing and hiring skills', label: 'Interviewing and hiring skills' },
    { key: 'Inventory control and management', label: 'Inventory control and management' },
    { key: 'Inventory management', label: 'Inventory management' },
    { key: 'Inventory management skills', label: 'Inventory management skills' },
    { key: 'Investment advising', label: 'Investment advising' },
    { key: 'Investment analysis', label: 'Investment analysis' },
    { key: 'Investment analysis software', label: 'Investment analysis software' },
    { key: 'Investment banking', label: 'Investment banking' },
    { key: 'Investment coaching', label: 'Investment coaching' },
    { key: 'Investment consulting', label: 'Investment consulting' },
    { key: 'Investment data analytics', label: 'Investment data analytics' },
    { key: 'Investment education', label: 'Investment education' },
    { key: 'Investment management', label: 'Investment management' },
    { key: 'Investment performance measurement', label: 'Investment performance measurement' },
    { key: 'Investment planning', label: 'Investment planning' },
    { key: 'Investment research', label: 'Investment research' },
    { key: 'Investment strategy', label: 'Investment strategy' },
    { key: 'Investor relations', label: 'Investor relations' },
    { key: 'Irrigation system design and management', label: 'Irrigation system design and management' },
    { key: 'ISO 27001', label: 'ISO 27001' },
    { key: 'IT governance', label: 'IT governance' },
    { key: 'IT infrastructure management', label: 'IT infrastructure management' },
    { key: 'IT security', label: 'IT security' },
    { key: 'IT strategy', label: 'IT strategy' },
    { key: 'IT strategy development', label: 'IT strategy development' },
    { key: 'ITIL', label: 'ITIL' },
    { key: 'Joint ventures and partnerships', label: 'Joint ventures and partnerships' },
    { key: 'Kaizen', label: 'Kaizen' },
    { key: 'Kanban', label: 'Kanban' },
    { key: 'Key performance indicator (KPI) development and tracking', label: 'Key performance indicator (KPI) development and tracking' },
    { key: 'Knowledge management', label: 'Knowledge management' },
    { key: 'Knowledge of agile development methodologies', label: 'Knowledge of agile development methodologies' },
    { key: 'Knowledge of branding and brand management principles', label: 'Knowledge of branding and brand management principles' },
    { key: 'Knowledge of building codes and regulations', label: 'Knowledge of building codes and regulations' },
    { key: 'Knowledge of chatbot and voice assistant development', label: 'Knowledge of chatbot and voice assistant development' },
    { key: 'Knowledge of content distribution networks, such as Outbrain or Taboola', label: 'Knowledge of content distribution networks, such as Outbrain or Taboola' },
    { key: 'Knowledge of content localization and translation', label: 'Knowledge of content localization and translation' },
    { key: 'Knowledge of copyright and intellectual property laws', label: 'Knowledge of copyright and intellectual property laws' },
    { key: 'Knowledge of crisis communications management', label: 'Knowledge of crisis communications management' },
    { key: 'Knowledge of cross-channel marketing and omnichannel strategies', label: 'Knowledge of cross-channel marketing and omnichannel strategies' },
    { key: 'Knowledge of different cooking techniques and styles', label: 'Knowledge of different cooking techniques and styles' },
    { key: 'Knowledge of different types of cuisine', label: 'Knowledge of different types of cuisine' },
    { key: 'Knowledge of digital accessibility best practices', label: 'Knowledge of digital accessibility best practices' },
    { key: 'Knowledge of digital marketing channels and strategies', label: 'Knowledge of digital marketing channels and strategies' },
    { key: 'Knowledge of e-commerce best practices and trends', label: 'Knowledge of e-commerce best practices and trends' },
    { key: 'Knowledge of email marketing and newsletter writing', label: 'Knowledge of email marketing and newsletter writing' },
    { key: 'Knowledge of environmental regulations', label: 'Knowledge of environmental regulations' },
    { key: 'Knowledge of experimental techniques', label: 'Knowledge of experimental techniques' },
    { key: 'Knowledge of financing options', label: 'Knowledge of financing options' },
    { key: 'Knowledge of food allergens and intolerances', label: 'Knowledge of food allergens and intolerances' },
    { key: 'Knowledge of food presentation and plating', label: 'Knowledge of food presentation and plating' },
    { key: 'Knowledge of food production and preparation', label: 'Knowledge of food production and preparation' },
    { key: 'Knowledge of food safety and quality regulations', label: 'Knowledge of food safety and quality regulations' },
    { key: 'Knowledge of food storage and preservation', label: 'Knowledge of food storage and preservation' },
    { key: 'Knowledge of influencer marketing and content partnerships', label: 'Knowledge of influencer marketing and content partnerships' },
    { key: 'Knowledge of kitchen equipment and maintenance', label: 'Knowledge of kitchen equipment and maintenance' },
    { key: 'Knowledge of laboratory safety protocols', label: 'Knowledge of laboratory safety protocols' },
    { key: 'Knowledge of local and international food regulations', label: 'Knowledge of local and international food regulations' },
    { key: 'Knowledge of nutrition and dietary needs', label: 'Knowledge of nutrition and dietary needs' },
    { key: 'Knowledge of online community management and moderation', label: 'Knowledge of online community management and moderation' },
    { key: 'Knowledge of property management software', label: 'Knowledge of property management software' },
    { key: 'Knowledge of property valuation methods', label: 'Knowledge of property valuation methods' },
    { key: 'Knowledge of real estate appraisal methods', label: 'Knowledge of real estate appraisal methods' },
    { key: 'Knowledge of real estate crowdfunding', label: 'Knowledge of real estate crowdfunding' },
    { key: 'Knowledge of real estate development', label: 'Knowledge of real estate development' },
    { key: 'Knowledge of real estate investment analysis', label: 'Knowledge of real estate investment analysis' },
    { key: 'Knowledge of real estate investment trusts (REITs)', label: 'Knowledge of real estate investment trusts (REITs)' },
    { key: 'Knowledge of real estate leasing', label: 'Knowledge of real estate leasing' },
    { key: 'Knowledge of real estate syndications', label: 'Knowledge of real estate syndications' },
    { key: 'Knowledge of real estate technology tools', label: 'Knowledge of real estate technology tools' },
    { key: 'Knowledge of recipe development and testing', label: 'Knowledge of recipe development and testing' },
    { key: 'Knowledge of scientific ethics', label: 'Knowledge of scientific ethics' },
    { key: 'Knowledge of scientific instrumentation', label: 'Knowledge of scientific instrumentation' },
    { key: 'Knowledge of scientific publishing practices', label: 'Knowledge of scientific publishing practices' },
    { key: 'Knowledge of search engine optimization (SEO) best practices', label: 'Knowledge of search engine optimization (SEO) best practices' },
    { key: 'Knowledge of seed genetics and breeding', label: 'Knowledge of seed genetics and breeding' },
    { key: 'Knowledge of SEO and content marketing strategies', label: 'Knowledge of SEO and content marketing strategies' },
    { key: 'Knowledge of social media crisis management', label: 'Knowledge of social media crisis management' },
    { key: 'Knowledge of social media platforms and their audiences', label: 'Knowledge of social media platforms and their audiences' },
    { key: 'Knowledge of tax laws and regulations', label: 'Knowledge of tax laws and regulations' },
    { key: 'Knowledge of the global real estate market', label: 'Knowledge of the global real estate market' },
    { key: 'Knowledge of the local real estate market', label: 'Knowledge of the local real estate market' },
    { key: 'Knowledge of the national real estate market', label: 'Knowledge of the national real estate market' },
    { key: 'Knowledge of zoning laws and regulations', label: 'Knowledge of zoning laws and regulations' },
    { key: 'Labor and employment law', label: 'Labor and employment law' },
    { key: 'Land management', label: 'Land management' },
    { key: 'Land use planning and management', label: 'Land use planning and management' },
    { key: 'Language proficiency and translation', label: 'Language proficiency and translation' },
    { key: 'Law enforcement', label: 'Law enforcement' },
    { key: 'Lead generation', label: 'Lead generation' },
    { key: 'Lead nurturing', label: 'Lead nurturing' },
    { key: 'Lead scoring and prioritization', label: 'Lead scoring and prioritization' },
    { key: 'Leadership', label: 'Leadership' },
    { key: 'Leadership and team management', label: 'Leadership and team management' },
    { key: 'Leadership development', label: 'Leadership development' },
    { key: 'Leadership skills', label: 'Leadership skills' },
    { key: 'Lean development', label: 'Lean development' },
    { key: 'Lean management', label: 'Lean management' },
    { key: 'Lean manufacturing', label: 'Lean manufacturing' },
    { key: 'Lean manufacturing and continuous improvement', label: 'Lean manufacturing and continuous improvement' },
    { key: 'Lean methodology', label: 'Lean methodology' },
    { key: 'Learning and development', label: 'Learning and development' },
    { key: 'Legal analysis', label: 'Legal analysis' },
    { key: 'Legal and business affairs', label: 'Legal and business affairs' },
    { key: 'Legal and compliance knowledge', label: 'Legal and compliance knowledge' },
    { key: 'Legal compliance', label: 'Legal compliance' },
    { key: 'Legal compliance and regulatory affairs', label: 'Legal compliance and regulatory affairs' },
    { key: 'Legal compliance and regulatory affairs for the arts', label: 'Legal compliance and regulatory affairs for the arts' },
    { key: 'Legal document review', label: 'Legal document review' },
    { key: 'Legal ethics', label: 'Legal ethics' },
    { key: 'Legal knowledge and compliance', label: 'Legal knowledge and compliance' },
    { key: 'Legal practice management software', label: 'Legal practice management software' },
    { key: 'Legal project management', label: 'Legal project management' },
    { key: 'Legal research', label: 'Legal research' },
    { key: 'Legal research and writing', label: 'Legal research and writing' },
    { key: 'Legal research software', label: 'Legal research software' },
    { key: 'Legal services', label: 'Legal services' },
    { key: 'Legal writing', label: 'Legal writing' },
    { key: 'Legislative affairs', label: 'Legislative affairs' },
    { key: 'Library and information science', label: 'Library and information science' },
    { key: 'Licensing and technology transfer', label: 'Licensing and technology transfer' },
    { key: 'Lighting', label: 'Lighting' },
    { key: 'Lighting and sound design', label: 'Lighting and sound design' },
    { key: 'Lighting design', label: 'Lighting design' },
    { key: 'Lighting equipment maintenance', label: 'Lighting equipment maintenance' },
    { key: 'Lighting equipment operation', label: 'Lighting equipment operation' },
    { key: 'Liquidity risk management', label: 'Liquidity risk management' },
    { key: 'Listening skills', label: 'Listening skills' },
    { key: 'Litigation', label: 'Litigation' },
    { key: 'Litigation management', label: 'Litigation management' },
    { key: 'Litigation support software', label: 'Litigation support software' },
    { key: 'Live performance and concert production', label: 'Live performance and concert production' },
    { key: 'Livestock management and breeding', label: 'Livestock management and breeding' },
    { key: 'LNG operations', label: 'LNG operations' },
    { key: 'Load testing', label: 'Load testing' },
    { key: 'Location scouting', label: 'Location scouting' },
    { key: 'Logical reasoning', label: 'Logical reasoning' },
    { key: 'Logistics and distribution', label: 'Logistics and distribution' },
    { key: 'Logistics and distribution management', label: 'Logistics and distribution management' },
    { key: 'Logistics and transportation', label: 'Logistics and transportation' },
    { key: 'Logistics management', label: 'Logistics management' },
    { key: 'Low voltage engineering', label: 'Low voltage engineering' },
    { key: 'Loyalty program design and management', label: 'Loyalty program design and management' },
    { key: 'Lubricants and additives', label: 'Lubricants and additives' },
    { key: 'Machine learning', label: 'Machine learning' },
    { key: 'Machine learning and AI skills', label: 'Machine learning and AI skills' },
    { key: 'Machine learning and artificial intelligence', label: 'Machine learning and artificial intelligence' },
    { key: 'Machine learning development', label: 'Machine learning development' },
    { key: 'Machine learning: TensorFlow, PyTorch, scikit-learn, and more.', label: 'Machine learning: TensorFlow, PyTorch, scikit-learn, and more.' },
    { key: 'Magnetic resonance imaging knowledge and skills', label: 'Magnetic resonance imaging knowledge and skills' },
    { key: 'Maintenance management', label: 'Maintenance management' },
    { key: 'Makeup and hair', label: 'Makeup and hair' },
    { key: 'Management', label: 'Management' },
    { key: 'Manufacturing and production', label: 'Manufacturing and production' },
    { key: 'Manufacturing processes and systems', label: 'Manufacturing processes and systems' },
    { key: 'Market analysis', label: 'Market analysis' },
    { key: 'Market analysis and competitive research', label: 'Market analysis and competitive research' },
    { key: 'Market entry strategy', label: 'Market entry strategy' },
    { key: 'Market research', label: 'Market research' },
    { key: 'Market research and analysis', label: 'Market research and analysis' },
    { key: 'Market risk management', label: 'Market risk management' },
    { key: 'Market segmentation', label: 'Market segmentation' },
    { key: 'Market segmentation and targeting', label: 'Market segmentation and targeting' },
    { key: 'Market trend analysis and forecasting', label: 'Market trend analysis and forecasting' },
    { key: 'Marketing', label: 'Marketing' },
    { key: 'Marketing analytics and reporting', label: 'Marketing analytics and reporting' },
    { key: 'Marketing and branding', label: 'Marketing and branding' },
    { key: 'Marketing and branding for the arts', label: 'Marketing and branding for the arts' },
    { key: 'Marketing and communication', label: 'Marketing and communication' },
    { key: 'Marketing and promotional skills', label: 'Marketing and promotional skills' },
    { key: 'Marketing and sales', label: 'Marketing and sales' },
    { key: 'Marketing automation', label: 'Marketing automation' },
    { key: 'Marketing budgeting and forecasting', label: 'Marketing budgeting and forecasting' },
    { key: 'Marketing collateral creation', label: 'Marketing collateral creation' },
    { key: 'Marketing dashboards and reporting', label: 'Marketing dashboards and reporting' },
    { key: 'Marketing measurement', label: 'Marketing measurement' },
    { key: 'Marketing project management', label: 'Marketing project management' },
    { key: 'Marketing research', label: 'Marketing research' },
    { key: 'Marketing skills', label: 'Marketing skills' },
    { key: 'Marketing strategy', label: 'Marketing strategy' },
    { key: 'Material science and engineering', label: 'Material science and engineering' },
    { key: 'Material selection', label: 'Material selection' },
    { key: 'Materials engineering', label: 'Materials engineering' },
    { key: 'Materials management', label: 'Materials management' },
    { key: 'Materials science', label: 'Materials science' },
    { key: 'Materials science knowledge and skills', label: 'Materials science knowledge and skills' },
    { key: 'Mathematical skills', label: 'Mathematical skills' },
    { key: 'Mathematics', label: 'Mathematics' },
    { key: 'Mechanical engineering', label: 'Mechanical engineering' },
    { key: 'Mechanical system design', label: 'Mechanical system design' },
    { key: 'Media planning and buying', label: 'Media planning and buying' },
    { key: 'Media relations', label: 'Media relations' },
    { key: 'Media relations and communications', label: 'Media relations and communications' },
    { key: 'Mediation and arbitration', label: 'Mediation and arbitration' },
    { key: 'Mentoring', label: 'Mentoring' },
    { key: 'Mentoring skills', label: 'Mentoring skills' },
    { key: 'Menu development skills', label: 'Menu development skills' },
    { key: 'Menu engineering and analysis', label: 'Menu engineering and analysis' },
    { key: 'Mergers and acquisitions', label: 'Mergers and acquisitions' },
    { key: 'Metallurgy', label: 'Metallurgy' },
    { key: 'Metalworking and machining', label: 'Metalworking and machining' },
    { key: 'Microgrids', label: 'Microgrids' },
    { key: 'Microphone selection and placement', label: 'Microphone selection and placement' },
    { key: 'Microservices architecture: design and development of small, independent services.', label: 'Microservices architecture: design and development of small, independent services.' },
    { key: 'Midstream operations', label: 'Midstream operations' },
    { key: 'Military operations', label: 'Military operations' },
    { key: 'Mine closure and reclamation planning', label: 'Mine closure and reclamation planning' },
    { key: 'Mine tailings management and disposal', label: 'Mine tailings management and disposal' },
    { key: 'Mineral processing and metallurgy', label: 'Mineral processing and metallurgy' },
    { key: 'Mobile app design and development', label: 'Mobile app design and development' },
    { key: 'Mobile app development', label: 'Mobile app development' },
    { key: 'Mobile app development: Android, iOS, Flutter, React Native, Ionic, and more.', label: 'Mobile app development: Android, iOS, Flutter, React Native, Ionic, and more.' },
    { key: 'Mobile game development', label: 'Mobile game development' },
    { key: 'Mobile network architecture', label: 'Mobile network architecture' },
    { key: 'Monitoring and evaluation', label: 'Monitoring and evaluation' },
    { key: 'Motion graphics and visual effects', label: 'Motion graphics and visual effects' },
    { key: 'Motion graphics design software', label: 'Motion graphics design software' },
    { key: 'Motion graphics software', label: 'Motion graphics software' },
    { key: 'Motivation', label: 'Motivation' },
    { key: 'Multi-channel marketing.', label: 'Multi-channel marketing.' },
    { key: 'Multicultural competence', label: 'Multicultural competence' },
    { key: 'Multicultural sensitivity', label: 'Multicultural sensitivity' },
    { key: 'Multitasking', label: 'Multitasking' },
    { key: 'Multitasking skills', label: 'Multitasking skills' },
    { key: 'Museology', label: 'Museology' },
    { key: 'Museology and museum management', label: 'Museology and museum management' },
    { key: 'Music arrangement', label: 'Music arrangement' },
    { key: 'Music composition', label: 'Music composition' },
    { key: 'Music composition software', label: 'Music composition software' },
    { key: 'Music editing', label: 'Music editing' },
    { key: 'Music licensing', label: 'Music licensing' },
    { key: 'Music licensing and royalty management', label: 'Music licensing and royalty management' },
    { key: 'Music notation', label: 'Music notation' },
    { key: 'Music production', label: 'Music production' },
    { key: 'Music production and composition', label: 'Music production and composition' },
    { key: 'Music production software', label: 'Music production software' },
    { key: 'Music theory', label: 'Music theory' },
    { key: 'Music theory and composition', label: 'Music theory and composition' },
    { key: 'Nanotechnology', label: 'Nanotechnology' },
    { key: 'Natural language processing', label: 'Natural language processing' },
    { key: 'Natural language processing: NLTK, spaCy, and more.', label: 'Natural language processing: NLTK, spaCy, and more.' },
    { key: 'Natural resource accounting and financial reporting', label: 'Natural resource accounting and financial reporting' },
    { key: 'Natural resource asset management and valuation', label: 'Natural resource asset management and valuation' },
    { key: 'Natural resource conflict resolution and mediation', label: 'Natural resource conflict resolution and mediation' },
    { key: 'Natural resource economics and policy', label: 'Natural resource economics and policy' },
    { key: 'Natural resource law and regulation', label: 'Natural resource law and regulation' },
    { key: 'Natural resource market analysis and forecasting', label: 'Natural resource market analysis and forecasting' },
    { key: 'Natural resource project financing and investment', label: 'Natural resource project financing and investment' },
    { key: 'Natural resource project management', label: 'Natural resource project management' },
    { key: 'Natural resource supply chain logistics', label: 'Natural resource supply chain logistics' },
    { key: 'Needs assessment', label: 'Needs assessment' },
    { key: 'Negotiation', label: 'Negotiation' },
    { key: 'Negotiation and conflict resolution', label: 'Negotiation and conflict resolution' },
    { key: 'Negotiation and contract management', label: 'Negotiation and contract management' },
    { key: 'Negotiation skills', label: 'Negotiation skills' },
    { key: 'Net promoter score (NPS) tracking and analysis', label: 'Net promoter score (NPS) tracking and analysis' },
    { key: 'Network design and optimization', label: 'Network design and optimization' },
    { key: 'Network function virtualization (NFV)', label: 'Network function virtualization (NFV)' },
    { key: 'Network performance management', label: 'Network performance management' },
    { key: 'Network security', label: 'Network security' },
    { key: 'Networking', label: 'Networking' },
    { key: 'Networking and collaboration skills', label: 'Networking and collaboration skills' },
    { key: 'Networking and relationship building', label: 'Networking and relationship building' },
    { key: 'Networking skills', label: 'Networking skills' },
    { key: 'Neutron scattering knowledge and skills', label: 'Neutron scattering knowledge and skills' },
    { key: 'Non-linear editing software', label: 'Non-linear editing software' },
    { key: 'Nonprofit management and governance for arts organizations', label: 'Nonprofit management and governance for arts organizations' },
    { key: 'Nuclear engineering', label: 'Nuclear engineering' },
    { key: 'Nuclear magnetic resonance knowledge and skills', label: 'Nuclear magnetic resonance knowledge and skills' },
    { key: 'Nuclear operations', label: 'Nuclear operations' },
    { key: 'Nuclear physics knowledge and skills', label: 'Nuclear physics knowledge and skills' },
    { key: 'Nuclear regulatory compliance', label: 'Nuclear regulatory compliance' },
    { key: 'Nuclear safety', label: 'Nuclear safety' },
    { key: 'Nuclear waste management', label: 'Nuclear waste management' },
    { key: 'Occupational health and safety', label: 'Occupational health and safety' },
    { key: 'Occupational health and safety in natural resource industries', label: 'Occupational health and safety in natural resource industries' },
    { key: 'Offshoring', label: 'Offshoring' },
    { key: 'Oil and gas asset valuation and due diligence', label: 'Oil and gas asset valuation and due diligence' },
    { key: 'Oil and gas drilling and production', label: 'Oil and gas drilling and production' },
    { key: 'Oil and gas drilling engineering and design', label: 'Oil and gas drilling engineering and design' },
    { key: 'Oil and gas environmental compliance and remediation', label: 'Oil and gas environmental compliance and remediation' },
    { key: 'Oil and gas exploration', label: 'Oil and gas exploration' },
    { key: 'Oil and gas exploration risk assessment and management', label: 'Oil and gas exploration risk assessment and management' },
    { key: 'Oil and gas facilities engineering and design', label: 'Oil and gas facilities engineering and design' },
    { key: 'Oil and gas field development planning', label: 'Oil and gas field development planning' },
    { key: 'Oil and gas field operations management', label: 'Oil and gas field operations management' },
    { key: 'Oil and gas geophysics and seismic interpretation', label: 'Oil and gas geophysics and seismic interpretation' },
    { key: 'Oil and gas pipeline design and engineering', label: 'Oil and gas pipeline design and engineering' },
    { key: 'Oil and gas production optimization and management', label: 'Oil and gas production optimization and management' },
    { key: 'Oil and gas project cost estimation and control', label: 'Oil and gas project cost estimation and control' },
    { key: 'Oil and gas regulatory compliance and reporting', label: 'Oil and gas regulatory compliance and reporting' },
    { key: 'Oil and gas reserve estimation and reporting', label: 'Oil and gas reserve estimation and reporting' },
    { key: 'Oil and gas reservoir management and optimization', label: 'Oil and gas reservoir management and optimization' },
    { key: 'Oil and gas reservoir modeling and simulation', label: 'Oil and gas reservoir modeling and simulation' },
    { key: 'Oil and gas well completion and stimulation techniques', label: 'Oil and gas well completion and stimulation techniques' },
    { key: 'Oil and gas well integrity management', label: 'Oil and gas well integrity management' },
    { key: 'Oil and gas well logging and interpretation', label: 'Oil and gas well logging and interpretation' },
    { key: 'Oilfield services', label: 'Oilfield services' },
    { key: 'Onboarding and employee retention', label: 'Onboarding and employee retention' },
    { key: 'Online marketplace development', label: 'Online marketplace development' },
    { key: 'Operational excellence', label: 'Operational excellence' },
    { key: 'Operational risk management', label: 'Operational risk management' },
    { key: 'Operations management', label: 'Operations management' },
    { key: 'Operations management and logistics', label: 'Operations management and logistics' },
    { key: 'Operations research', label: 'Operations research' },
    { key: 'Optics knowledge and skills', label: 'Optics knowledge and skills' },
    { key: 'Optimization', label: 'Optimization' },
    { key: 'Orchestration', label: 'Orchestration' },
    { key: 'Order fulfillment', label: 'Order fulfillment' },
    { key: 'Organic farming techniques and certification requirements', label: 'Organic farming techniques and certification requirements' },
    { key: 'Organization', label: 'Organization' },
    { key: 'Organization and prioritization', label: 'Organization and prioritization' },
    { key: 'Organization skills', label: 'Organization skills' },
    { key: 'Organizational design', label: 'Organizational design' },
    { key: 'Organizational development', label: 'Organizational development' },
    { key: 'Organizational skills', label: 'Organizational skills' },
    { key: 'Outsourcing', label: 'Outsourcing' },
    { key: 'Outsourcing and offshoring', label: 'Outsourcing and offshoring' },
    { key: 'Packaging design', label: 'Packaging design' },
    { key: 'Painting and mixed media', label: 'Painting and mixed media' },
    { key: 'Particle physics knowledge and skills', label: 'Particle physics knowledge and skills' },
    { key: 'Partner relationship management', label: 'Partner relationship management' },
    { key: 'Partnership building', label: 'Partnership building' },
    { key: 'Partnership management', label: 'Partnership management' },
    { key: 'Patent law', label: 'Patent law' },
    { key: 'Patent law and trademark registration', label: 'Patent law and trademark registration' },
    { key: 'Patents and trademarks', label: 'Patents and trademarks' },
    { key: 'Pay-per-click (PPC) advertising', label: 'Pay-per-click (PPC) advertising' },
    { key: 'Pay-per-click (PPC) advertising skills', label: 'Pay-per-click (PPC) advertising skills' },
    { key: 'Pay-per-click (PPC) advertising: Google Ads, Facebook Ads', label: 'Pay-per-click (PPC) advertising: Google Ads, Facebook Ads' },
    { key: 'Pay-per-click advertising', label: 'Pay-per-click advertising' },
    { key: 'Payment gateway integration', label: 'Payment gateway integration' },
    { key: 'Payment gateway integration: Stripe, PayPal, and more.', label: 'Payment gateway integration: Stripe, PayPal, and more.' },
    { key: 'Payment systems', label: 'Payment systems' },
    { key: 'PCI DSS', label: 'PCI DSS' },
    { key: 'Performance coaching', label: 'Performance coaching' },
    { key: 'Performance management', label: 'Performance management' },
    { key: 'Performance management and coaching', label: 'Performance management and coaching' },
    { key: 'Performance measurement', label: 'Performance measurement' },
    { key: 'Performance measurement and reporting', label: 'Performance measurement and reporting' },
    { key: 'Performance optimization', label: 'Performance optimization' },
    { key: 'Performance optimization and process improvement', label: 'Performance optimization and process improvement' },
    { key: 'Performance tuning', label: 'Performance tuning' },
    { key: 'Performance-based design', label: 'Performance-based design' },
    { key: 'Permitting and environmental approvals', label: 'Permitting and environmental approvals' },
    { key: 'Persuasion', label: 'Persuasion' },
    { key: 'Persuasion skills', label: 'Persuasion skills' },
    { key: 'Pest and disease management', label: 'Pest and disease management' },
    { key: 'Petrochemical operations', label: 'Petrochemical operations' },
    { key: 'Petrochemicals', label: 'Petrochemicals' },
    { key: 'Petroleum geology', label: 'Petroleum geology' },
    { key: 'Philanthropy and social responsibility', label: 'Philanthropy and social responsibility' },
    { key: 'Photography', label: 'Photography' },
    { key: 'Photography and photo editing', label: 'Photography and photo editing' },
    { key: 'Photography and videography', label: 'Photography and videography' },
    { key: 'Physical chemistry knowledge and skills', label: 'Physical chemistry knowledge and skills' },
    { key: 'Physics', label: 'Physics' },
    { key: 'Pipeline engineering', label: 'Pipeline engineering' },
    { key: 'Pipeline management', label: 'Pipeline management' },
    { key: 'Piping engineering', label: 'Piping engineering' },
    { key: 'Planning', label: 'Planning' },
    { key: 'Planning and organization', label: 'Planning and organization' },
    { key: 'Plant breeding and genetics', label: 'Plant breeding and genetics' },
    { key: 'Policy analysis', label: 'Policy analysis' },
    { key: 'Policy development', label: 'Policy development' },
    { key: 'Political savvy', label: 'Political savvy' },
    { key: 'Polymer science and engineering', label: 'Polymer science and engineering' },
    { key: 'Portfolio management', label: 'Portfolio management' },
    { key: 'Post-production', label: 'Post-production' },
    { key: 'Post-production coordination', label: 'Post-production coordination' },
    { key: 'Post-production sound editing and mixing', label: 'Post-production sound editing and mixing' },
    { key: 'Power distribution', label: 'Power distribution' },
    { key: 'Power electronics', label: 'Power electronics' },
    { key: 'Power generation', label: 'Power generation' },
    { key: 'Power grid maintenance', label: 'Power grid maintenance' },
    { key: 'Power grid operations', label: 'Power grid operations' },
    { key: 'Power plant control systems', label: 'Power plant control systems' },
    { key: 'Power plant design', label: 'Power plant design' },
    { key: 'Power plant maintenance', label: 'Power plant maintenance' },
    { key: 'Power plant operations', label: 'Power plant operations' },
    { key: 'Power system analysis', label: 'Power system analysis' },
    { key: 'Power system economics', label: 'Power system economics' },
    { key: 'Power system planning', label: 'Power system planning' },
    { key: 'Power system protection', label: 'Power system protection' },
    { key: 'Power system reliability', label: 'Power system reliability' },
    { key: 'Power system simulation software', label: 'Power system simulation software' },
    { key: 'Power systems engineering', label: 'Power systems engineering' },
    { key: 'Power transmission', label: 'Power transmission' },
    { key: 'Power transmission and distribution', label: 'Power transmission and distribution' },
    { key: 'Practice-specific software', label: 'Practice-specific software' },
    { key: 'Pre-visualization software', label: 'Pre-visualization software' },
    { key: 'Precision agriculture techniques and technologies', label: 'Precision agriculture techniques and technologies' },
    { key: 'Precision measurement and calibration', label: 'Precision measurement and calibration' },
    { key: 'Predictive maintenance', label: 'Predictive maintenance' },
    { key: 'Predictive modeling', label: 'Predictive modeling' },
    { key: 'Presentation and public speaking skills', label: 'Presentation and public speaking skills' },
    { key: 'Presentation skills', label: 'Presentation skills' },
    { key: 'Pricing strategy', label: 'Pricing strategy' },
    { key: 'Pricing strategy development', label: 'Pricing strategy development' },
    { key: 'Printmaking', label: 'Printmaking' },
    { key: 'Prioritization', label: 'Prioritization' },
    { key: 'Privacy law', label: 'Privacy law' },
    { key: 'Private equity management', label: 'Private equity management' },
    { key: 'Pro Tools', label: 'Pro Tools' },
    { key: 'Probability and modeling skills', label: 'Probability and modeling skills' },
    { key: 'Problem management', label: 'Problem management' },
    { key: 'Problem solving', label: 'Problem solving' },
    { key: 'Problem-solving', label: 'Problem-solving' },
    { key: 'Problem-solving and decision-making', label: 'Problem-solving and decision-making' },
    { key: 'Problem-solving skills', label: 'Problem-solving skills' },
    { key: 'Process engineering', label: 'Process engineering' },
    { key: 'Process engineering and optimization', label: 'Process engineering and optimization' },
    { key: 'Process improvement', label: 'Process improvement' },
    { key: 'Process improvement.', label: 'Process improvement.' },
    { key: 'Process safety management', label: 'Process safety management' },
    { key: 'Procurement', label: 'Procurement' },
    { key: 'Procurement and contract negotiation', label: 'Procurement and contract negotiation' },
    { key: 'Procurement and vendor management', label: 'Procurement and vendor management' },
    { key: 'Procurement strategy', label: 'Procurement strategy' },
    { key: 'Product design', label: 'Product design' },
    { key: 'Product design and development', label: 'Product design and development' },
    { key: 'Product development', label: 'Product development' },
    { key: 'Product development and innovation', label: 'Product development and innovation' },
    { key: 'Product launch planning and execution', label: 'Product launch planning and execution' },
    { key: 'Product management', label: 'Product management' },
    { key: 'Product positioning and messaging', label: 'Product positioning and messaging' },
    { key: 'Production coordination', label: 'Production coordination' },
    { key: 'Production design', label: 'Production design' },
    { key: 'Production design and art direction for film and TV', label: 'Production design and art direction for film and TV' },
    { key: 'Production design software', label: 'Production design software' },
    { key: 'Production engineering', label: 'Production engineering' },
    { key: 'Production management software', label: 'Production management software' },
    { key: 'Production planning', label: 'Production planning' },
    { key: 'Production planning and scheduling', label: 'Production planning and scheduling' },
    { key: 'Production scheduling and logistics', label: 'Production scheduling and logistics' },
    { key: 'Proficiency in using digital tools and platforms', label: 'Proficiency in using digital tools and platforms' },
    { key: 'Program design', label: 'Program design' },
    { key: 'Program evaluation', label: 'Program evaluation' },
    { key: 'Program implementation', label: 'Program implementation' },
    { key: 'Program management', label: 'Program management' },
    { key: 'Programmable logic controllers (PLCs)', label: 'Programmable logic controllers (PLCs)' },
    { key: 'Programming', label: 'Programming' },
    { key: 'Programming and coding', label: 'Programming and coding' },
    { key: 'Programming languages: HTML, CSS, JavaScript, Python, Java, PHP, Ruby, C++, C#, Swift, Kotlin, Objective-C, TypeScript, SQL, and more.', label: 'Programming languages: HTML, CSS, JavaScript, Python, Java, PHP, Ruby, C++, C#, Swift, Kotlin, Objective-C, TypeScript, SQL, and more.' },
    { key: 'Programming skills', label: 'Programming skills' },
    { key: 'Project budgeting', label: 'Project budgeting' },
    { key: 'Project coordination', label: 'Project coordination' },
    { key: 'Project evaluation and assessment', label: 'Project evaluation and assessment' },
    { key: 'Project financing and funding', label: 'Project financing and funding' },
    { key: 'Project management', label: 'Project management' },
    { key: 'Project management and implementation', label: 'Project management and implementation' },
    { key: 'Project management and planning', label: 'Project management and planning' },
    { key: 'Project management and planning for the arts', label: 'Project management and planning for the arts' },
    { key: 'Project management methodologies', label: 'Project management methodologies' },
    { key: 'Project management skills', label: 'Project management skills' },
    { key: 'Project management: JIRA, Asana, Trello, and more.', label: 'Project management: JIRA, Asana, Trello, and more.' },
    { key: 'Project portfolio management', label: 'Project portfolio management' },
    { key: 'Project risk assessment', label: 'Project risk assessment' },
    { key: 'Project risk management', label: 'Project risk management' },
    { key: 'Property management', label: 'Property management' },
    { key: 'Proposal writing', label: 'Proposal writing' },
    { key: 'Psychology', label: 'Psychology' },
    { key: 'Public administration', label: 'Public administration' },
    { key: 'Public affairs', label: 'Public affairs' },
    { key: 'Public health', label: 'Public health' },
    { key: 'Public international law', label: 'Public international law' },
    { key: 'Public policy', label: 'Public policy' },
    { key: 'Public policy research', label: 'Public policy research' },
    { key: 'Public relations', label: 'Public relations' },
    { key: 'Public relations (PR)', label: 'Public relations (PR)' },
    { key: 'Public relations and crisis management', label: 'Public relations and crisis management' },
    { key: 'Public relations and marketing', label: 'Public relations and marketing' },
    { key: 'Public relations and media relations for the arts', label: 'Public relations and media relations for the arts' },
    { key: 'Public service delivery', label: 'Public service delivery' },
    { key: 'Public speaking', label: 'Public speaking' },
    { key: 'Public speaking and presentation skills', label: 'Public speaking and presentation skills' },
    { key: 'Public speaking skills', label: 'Public speaking skills' },
    { key: 'Pumped hydro energy storage', label: 'Pumped hydro energy storage' },
    { key: 'Purchasing and procurement skills', label: 'Purchasing and procurement skills' },
    { key: 'Quality assurance', label: 'Quality assurance' },
    { key: 'Quality assurance and quality control', label: 'Quality assurance and quality control' },
    { key: 'Quality control', label: 'Quality control' },
    { key: 'Quality control and assurance', label: 'Quality control and assurance' },
    { key: 'Quality management', label: 'Quality management' },
    { key: 'Quantitative analysis', label: 'Quantitative analysis' },
    { key: 'Quantum mechanics knowledge and skills', label: 'Quantum mechanics knowledge and skills' },
    { key: 'Radio access network (RAN) design', label: 'Radio access network (RAN) design' },
    { key: 'Real estate investment', label: 'Real estate investment' },
    { key: 'Real estate law', label: 'Real estate law' },
    { key: 'Recruitment marketing', label: 'Recruitment marketing' },
    { key: 'Referral marketing', label: 'Referral marketing' },
    { key: 'Refinery operations', label: 'Refinery operations' },
    { key: 'Refining', label: 'Refining' },
    { key: 'Regulatory affairs and compliance', label: 'Regulatory affairs and compliance' },
    { key: 'Regulatory compliance', label: 'Regulatory compliance' },
    { key: 'Regulatory compliance and reporting', label: 'Regulatory compliance and reporting' },
    { key: 'Regulatory compliance and safety management', label: 'Regulatory compliance and safety management' },
    { key: 'Relationship building', label: 'Relationship building' },
    { key: 'Relationship management', label: 'Relationship management' },
    { key: 'Relationship management tools and techniques', label: 'Relationship management tools and techniques' },
    { key: 'Relationship-building', label: 'Relationship-building' },
    { key: 'Relativity knowledge and skills', label: 'Relativity knowledge and skills' },
    { key: 'Reliability engineering', label: 'Reliability engineering' },
    { key: 'Renewable energy', label: 'Renewable energy' },
    { key: 'Renewable energy certificates (RECs)', label: 'Renewable energy certificates (RECs)' },
    { key: 'Renewable energy project development and management', label: 'Renewable energy project development and management' },
    { key: 'Renewable energy systems', label: 'Renewable energy systems' },
    { key: 'Renewable energy technologies and development', label: 'Renewable energy technologies and development' },
    { key: 'Reputation management', label: 'Reputation management' },
    { key: 'Research', label: 'Research' },
    { key: 'Research and analysis', label: 'Research and analysis' },
    { key: 'Research and development', label: 'Research and development' },
    { key: 'Research and development of new technologies', label: 'Research and development of new technologies' },
    { key: 'Research skills', label: 'Research skills' },
    { key: 'Reservoir engineering', label: 'Reservoir engineering' },
    { key: 'Resilience', label: 'Resilience' },
    { key: 'Resource allocation', label: 'Resource allocation' },
    { key: 'Resource allocation software', label: 'Resource allocation software' },
    { key: 'Resource exploration and geology', label: 'Resource exploration and geology' },
    { key: 'Resource extraction impact mitigation', label: 'Resource extraction impact mitigation' },
    { key: 'Resource management', label: 'Resource management' },
    { key: 'Resource mobilization', label: 'Resource mobilization' },
    { key: 'Responsive design', label: 'Responsive design' },
    { key: 'Responsive design: design and development for multiple devices and screen sizes.', label: 'Responsive design: design and development for multiple devices and screen sizes.' },
    { key: 'Results-based management', label: 'Results-based management' },
    { key: 'Revit', label: 'Revit' },
    { key: 'Risk analysis', label: 'Risk analysis' },
    { key: 'Risk assessment', label: 'Risk assessment' },
    { key: 'Risk assessment and management', label: 'Risk assessment and management' },
    { key: 'Risk assessment and management in natural resource industries', label: 'Risk assessment and management in natural resource industries' },
    { key: 'Risk assessment and mitigation', label: 'Risk assessment and mitigation' },
    { key: 'Risk assessment skills', label: 'Risk assessment skills' },
    { key: 'Risk management', label: 'Risk management' },
    { key: 'Risk management in agriculture', label: 'Risk management in agriculture' },
    { key: 'Risk mitigation', label: 'Risk mitigation' },
    { key: 'Risk mitigation and insurance', label: 'Risk mitigation and insurance' },
    { key: 'Robotic process automation', label: 'Robotic process automation' },
    { key: 'Robotics', label: 'Robotics' },
    { key: 'Robotics development', label: 'Robotics development' },
    { key: 'Robotics programming and control', label: 'Robotics programming and control' },
    { key: 'ROI tracking', label: 'ROI tracking' },
    { key: 'Root cause analysis', label: 'Root cause analysis' },
    { key: 'Root cause analysis and problem solving', label: 'Root cause analysis and problem solving' },
    { key: 'Route optimization', label: 'Route optimization' },
    { key: 'Safety management', label: 'Safety management' },
    { key: 'Sales', label: 'Sales' },
    { key: 'Sales analytics and reporting', label: 'Sales analytics and reporting' },
    { key: 'Sales and business development', label: 'Sales and business development' },
    { key: 'Sales and customer relationship management (CRM) integration', label: 'Sales and customer relationship management (CRM) integration' },
    { key: 'Sales and marketing', label: 'Sales and marketing' },
    { key: 'Sales and marketing alignment', label: 'Sales and marketing alignment' },
    { key: 'Sales and revenue generation', label: 'Sales and revenue generation' },
    { key: 'Sales automation skills', label: 'Sales automation skills' },
    { key: 'Sales channel development', label: 'Sales channel development' },
    { key: 'Sales channel effectiveness measurement', label: 'Sales channel effectiveness measurement' },
    { key: 'Sales channel management', label: 'Sales channel management' },
    { key: 'Sales channel optimization', label: 'Sales channel optimization' },
    { key: 'Sales coaching and training', label: 'Sales coaching and training' },
    { key: 'Sales copywriting skills', label: 'Sales copywriting skills' },
    { key: 'Sales data analysis software skills', label: 'Sales data analysis software skills' },
    { key: 'Sales enablement and training', label: 'Sales enablement and training' },
    { key: 'Sales enablement tools and techniques', label: 'Sales enablement tools and techniques' },
    { key: 'Sales forecasting', label: 'Sales forecasting' },
    { key: 'Sales forecasting and budgeting', label: 'Sales forecasting and budgeting' },
    { key: 'Sales forecasting models', label: 'Sales forecasting models' },
    { key: 'Sales forecasting software skills', label: 'Sales forecasting software skills' },
    { key: 'Sales funnel analytics', label: 'Sales funnel analytics' },
    { key: 'Sales funnel optimization', label: 'Sales funnel optimization' },
    { key: 'Sales incentive programs', label: 'Sales incentive programs' },
    { key: 'Sales management', label: 'Sales management' },
    { key: 'Sales metrics and KPIs', label: 'Sales metrics and KPIs' },
    { key: 'Sales operations management', label: 'Sales operations management' },
    { key: 'Sales presentation skills', label: 'Sales presentation skills' },
    { key: 'Sales process optimization', label: 'Sales process optimization' },
    { key: 'Sales prospecting.', label: 'Sales prospecting.' },
    { key: 'Sales skills', label: 'Sales skills' },
    { key: 'Sales strategy', label: 'Sales strategy' },
    { key: 'Sales strategy development', label: 'Sales strategy development' },
    { key: 'Sales team collaboration software skills', label: 'Sales team collaboration software skills' },
    { key: 'Sales team communication and collaboration', label: 'Sales team communication and collaboration' },
    { key: 'Sales team management', label: 'Sales team management' },
    { key: 'Sales team productivity optimization', label: 'Sales team productivity optimization' },
    { key: 'Sales technology implementation and management', label: 'Sales technology implementation and management' },
    { key: 'Sales territory management', label: 'Sales territory management' },
    { key: 'Sales tracking and reporting software skills', label: 'Sales tracking and reporting software skills' },
    { key: 'SCADA systems', label: 'SCADA systems' },
    { key: 'Scanning probe microscopy knowledge and skills', label: 'Scanning probe microscopy knowledge and skills' },
    { key: 'Scheduling', label: 'Scheduling' },
    { key: 'Scheduling software', label: 'Scheduling software' },
    { key: 'Scientific research', label: 'Scientific research' },
    { key: 'Scientific writing skills', label: 'Scientific writing skills' },
    { key: 'SCM systems', label: 'SCM systems' },
    { key: 'Screenwriting and script analysis', label: 'Screenwriting and script analysis' },
    { key: 'Screenwriting and scriptwriting', label: 'Screenwriting and scriptwriting' },
    { key: 'Screenwriting software', label: 'Screenwriting software' },
    { key: 'Script analysis', label: 'Script analysis' },
    { key: 'Scriptwriting', label: 'Scriptwriting' },
    { key: 'Scrum', label: 'Scrum' },
    { key: 'Scrum management', label: 'Scrum management' },
    { key: 'Sculpture and installation art', label: 'Sculpture and installation art' },
    { key: 'Search engine optimization', label: 'Search engine optimization' },
    { key: 'Search engine optimization (SEO)', label: 'Search engine optimization (SEO)' },
    { key: 'Search engine optimization (SEO) skills', label: 'Search engine optimization (SEO) skills' },
    { key: 'Search engine optimization (SEO): keyword research, on-page optimization, and more.', label: 'Search engine optimization (SEO): keyword research, on-page optimization, and more.' },
    { key: 'Securities law', label: 'Securities law' },
    { key: 'Securities litigation', label: 'Securities litigation' },
    { key: 'Securities regulation', label: 'Securities regulation' },
    { key: 'Securities trading', label: 'Securities trading' },
    { key: 'Security and risk management', label: 'Security and risk management' },
    { key: 'Security: OWASP Top 10, SSL/TLS, authentication and authorization, and more.', label: 'Security: OWASP Top 10, SSL/TLS, authentication and authorization, and more.' },
    { key: 'Self-awareness', label: 'Self-awareness' },
    { key: 'Self-motivation', label: 'Self-motivation' },
    { key: 'Self-regulation', label: 'Self-regulation' },
    { key: 'SEO and SEM', label: 'SEO and SEM' },
    { key: 'Service delivery', label: 'Service delivery' },
    { key: 'Service delivery management', label: 'Service delivery management' },
    { key: 'Service design', label: 'Service design' },
    { key: 'Service desk management', label: 'Service desk management' },
    { key: 'Service level agreements', label: 'Service level agreements' },
    { key: 'Service management', label: 'Service management' },
    { key: 'Set construction', label: 'Set construction' },
    { key: 'Set design', label: 'Set design' },
    { key: 'Set design and construction', label: 'Set design and construction' },
    { key: 'Signal processing', label: 'Signal processing' },
    { key: 'Simulation and modeling skills', label: 'Simulation and modeling skills' },
    { key: 'Site analysis', label: 'Site analysis' },
    { key: 'Site planning', label: 'Site planning' },
    { key: 'Six Sigma', label: 'Six Sigma' },
    { key: 'Six Sigma methodology', label: 'Six Sigma methodology' },
    { key: 'SketchUp', label: 'SketchUp' },
    { key: 'Smart grid technology', label: 'Smart grid technology' },
    { key: 'Smart grids', label: 'Smart grids' },
    { key: 'Social awareness', label: 'Social awareness' },
    { key: 'Social justice', label: 'Social justice' },
    { key: 'Social media advertising', label: 'Social media advertising' },
    { key: 'Social media integration: Facebook, Twitter, Instagram, and more.', label: 'Social media integration: Facebook, Twitter, Instagram, and more.' },
    { key: 'Social media management', label: 'Social media management' },
    { key: 'Social media marketing', label: 'Social media marketing' },
    { key: 'Social media marketing and advertising', label: 'Social media marketing and advertising' },
    { key: 'Social media marketing skills', label: 'Social media marketing skills' },
    { key: 'Social media strategy and management', label: 'Social media strategy and management' },
    { key: 'Social policy', label: 'Social policy' },
    { key: 'Social selling skills', label: 'Social selling skills' },
    { key: 'Software development', label: 'Software development' },
    { key: 'Software development and engineering', label: 'Software development and engineering' },
    { key: 'Software engineering', label: 'Software engineering' },
    { key: 'Software testing', label: 'Software testing' },
    { key: 'Software-defined networking (SDN)', label: 'Software-defined networking (SDN)' },
    { key: 'Soil science and fertility management', label: 'Soil science and fertility management' },
    { key: 'Solar energy technologies and development', label: 'Solar energy technologies and development' },
    { key: 'Solar power', label: 'Solar power' },
    { key: 'Sound and lighting design', label: 'Sound and lighting design' },
    { key: 'Sound and music editing for film and TV', label: 'Sound and music editing for film and TV' },
    { key: 'Sound design', label: 'Sound design' },
    { key: 'Sound design and audio engineering', label: 'Sound design and audio engineering' },
    { key: 'Sound design software', label: 'Sound design software' },
    { key: 'Sound editing software', label: 'Sound editing software' },
    { key: 'Sound effects libraries', label: 'Sound effects libraries' },
    { key: 'Sound mixing', label: 'Sound mixing' },
    { key: 'Sound mixing software', label: 'Sound mixing software' },
    { key: 'Sound recording equipment operation', label: 'Sound recording equipment operation' },
    { key: 'Sound restoration', label: 'Sound restoration' },
    { key: 'Sound restoration software', label: 'Sound restoration software' },
    { key: 'SOX', label: 'SOX' },
    { key: 'Special effects', label: 'Special effects' },
    { key: 'Special effects and makeup', label: 'Special effects and makeup' },
    { key: 'Special effects software', label: 'Special effects software' },
    { key: 'Specification writing', label: 'Specification writing' },
    { key: 'Spectroscopy knowledge and skills', label: 'Spectroscopy knowledge and skills' },
    { key: 'Spectrum management', label: 'Spectrum management' },
    { key: 'Sponsorship and partnership management', label: 'Sponsorship and partnership management' },
    { key: 'Stakeholder engagement', label: 'Stakeholder engagement' },
    { key: 'Stakeholder engagement and consultation', label: 'Stakeholder engagement and consultation' },
    { key: 'Stakeholder engagement and public relations', label: 'Stakeholder engagement and public relations' },
    { key: 'Stakeholder engagement and public relations for the arts', label: 'Stakeholder engagement and public relations for the arts' },
    { key: 'Stakeholder management', label: 'Stakeholder management' },
    { key: 'Statistical analysis', label: 'Statistical analysis' },
    { key: 'Statistical analysis skills', label: 'Statistical analysis skills' },
    { key: 'Statistical process control', label: 'Statistical process control' },
    { key: 'Statistics', label: 'Statistics' },
    { key: 'Steam turbines', label: 'Steam turbines' },
    { key: 'Story development', label: 'Story development' },
    { key: 'Storyboarding', label: 'Storyboarding' },
    { key: 'Storyboarding and visualization', label: 'Storyboarding and visualization' },
    { key: 'Storytelling', label: 'Storytelling' },
    { key: 'Storytelling and narrative development', label: 'Storytelling and narrative development' },
    { key: 'Strategic communication', label: 'Strategic communication' },
    { key: 'Strategic partnership development', label: 'Strategic partnership development' },
    { key: 'Strategic partnerships', label: 'Strategic partnerships' },
    { key: 'Strategic partnerships and alliances', label: 'Strategic partnerships and alliances' },
    { key: 'Strategic partnerships development', label: 'Strategic partnerships development' },
    { key: 'Strategic planning', label: 'Strategic planning' },
    { key: 'Strategic planning and decision making', label: 'Strategic planning and decision making' },
    { key: 'Strategic planning and execution', label: 'Strategic planning and execution' },
    { key: 'Strategic planning and execution for the arts', label: 'Strategic planning and execution for the arts' },
    { key: 'Strategic planning and organizational development', label: 'Strategic planning and organizational development' },
    { key: 'Strategic planning and project management', label: 'Strategic planning and project management' },
    { key: 'Strategic planning skills', label: 'Strategic planning skills' },
    { key: 'Strategic sourcing', label: 'Strategic sourcing' },
    { key: 'Strategic thinking', label: 'Strategic thinking' },
    { key: 'Streaming and digital distribution', label: 'Streaming and digital distribution' },
    { key: 'Strong communication and interpersonal skills', label: 'Strong communication and interpersonal skills' },
    { key: 'Strong communication skills', label: 'Strong communication skills' },
    { key: 'Strong editing and proofreading skills', label: 'Strong editing and proofreading skills' },
    { key: 'Strong problem-solving abilities', label: 'Strong problem-solving abilities' },
    { key: 'Strong project management skills', label: 'Strong project management skills' },
    { key: 'Strong understanding of social media platforms and their audiences', label: 'Strong understanding of social media platforms and their audiences' },
    { key: 'Strong work ethic and commitment to excellence', label: 'Strong work ethic and commitment to excellence' },
    { key: 'Structural engineering', label: 'Structural engineering' },
    { key: 'Substation operations', label: 'Substation operations' },
    { key: 'Succession planning', label: 'Succession planning' },
    { key: 'Supplier management', label: 'Supplier management' },
    { key: 'Supplier relationship management', label: 'Supplier relationship management' },
    { key: 'Supply chain analytics', label: 'Supply chain analytics' },
    { key: 'Supply chain finance', label: 'Supply chain finance' },
    { key: 'Supply chain integration', label: 'Supply chain integration' },
    { key: 'Supply chain management', label: 'Supply chain management' },
    { key: 'Supply chain management and optimization', label: 'Supply chain management and optimization' },
    { key: 'Supply chain management in agriculture', label: 'Supply chain management in agriculture' },
    { key: 'Supply chain management in natural resource industries', label: 'Supply chain management in natural resource industries' },
    { key: 'Supply chain modeling', label: 'Supply chain modeling' },
    { key: 'Supply chain optimization', label: 'Supply chain optimization' },
    { key: 'Supply chain security', label: 'Supply chain security' },
    { key: 'Supply chain strategy', label: 'Supply chain strategy' },
    { key: 'Survey research', label: 'Survey research' },
    { key: 'Sustainability', label: 'Sustainability' },
    { key: 'Sustainable development', label: 'Sustainable development' },
    { key: 'Sustainable development principles and practices', label: 'Sustainable development principles and practices' },
    { key: 'Sustainable manufacturing practices', label: 'Sustainable manufacturing practices' },
    { key: 'Systems analysis', label: 'Systems analysis' },
    { key: 'Systems design', label: 'Systems design' },
    { key: 'Systems integration', label: 'Systems integration' },
    { key: 'Talent acquisition', label: 'Talent acquisition' },
    { key: 'Talent acquisition and recruitment', label: 'Talent acquisition and recruitment' },
    { key: 'Talent acquisition and retention', label: 'Talent acquisition and retention' },
    { key: 'Talent booking and management', label: 'Talent booking and management' },
    { key: 'Talent management', label: 'Talent management' },
    { key: 'Talent management software', label: 'Talent management software' },
    { key: 'Talent scouting and management', label: 'Talent scouting and management' },
    { key: 'Tax law', label: 'Tax law' },
    { key: 'Tax planning and compliance', label: 'Tax planning and compliance' },
    { key: 'Taxation', label: 'Taxation' },
    { key: 'Teaching skills', label: 'Teaching skills' },
    { key: 'Team building', label: 'Team building' },
    { key: 'Team building and coaching', label: 'Team building and coaching' },
    { key: 'Team building and leadership', label: 'Team building and leadership' },
    { key: 'Team building and motivation', label: 'Team building and motivation' },
    { key: 'Team collaboration software', label: 'Team collaboration software' },
    { key: 'Team leadership', label: 'Team leadership' },
    { key: 'Team leadership and management', label: 'Team leadership and management' },
    { key: 'Team management', label: 'Team management' },
    { key: 'Team management skills', label: 'Team management skills' },
    { key: 'Teamwork and collaboration skills', label: 'Teamwork and collaboration skills' },
    { key: 'Technical analysis', label: 'Technical analysis' },
    { key: 'Technical architecture', label: 'Technical architecture' },
    { key: 'Technical editing', label: 'Technical editing' },
    { key: 'Technical expertise', label: 'Technical expertise' },
    { key: 'Technical expertise in relevant tools and software', label: 'Technical expertise in relevant tools and software' },
    { key: 'Technical proficiency in a specific art form (e.g. painting, sculpture, music, dance, theater, film, photography, etc.)', label: 'Technical proficiency in a specific art form (e.g. painting, sculpture, music, dance, theater, film, photography, etc.)' },
    { key: 'Technical sales', label: 'Technical sales' },
    { key: 'Technical skills', label: 'Technical skills' },
    { key: 'Technical support', label: 'Technical support' },
    { key: 'Technical training', label: 'Technical training' },
    { key: 'Technical writing', label: 'Technical writing' },
    { key: 'Technical writing and documentation', label: 'Technical writing and documentation' },
    { key: 'Technology implementation', label: 'Technology implementation' },
    { key: 'Technology skills', label: 'Technology skills' },
    { key: 'Telecommunications engineering', label: 'Telecommunications engineering' },
    { key: 'Telecommunications systems', label: 'Telecommunications systems' },
    { key: 'Terminal operations', label: 'Terminal operations' },
    { key: 'Test automation', label: 'Test automation' },
    { key: 'Testing and certification', label: 'Testing and certification' },
    { key: 'Testing and experimentation', label: 'Testing and experimentation' },
    { key: 'Testing: unit testing, integration testing, automated testing, and more.', label: 'Testing: unit testing, integration testing, automated testing, and more.' },
    { key: 'Thermal energy storage', label: 'Thermal energy storage' },
    { key: 'Thermodynamics knowledge and skills', label: 'Thermodynamics knowledge and skills' },
    { key: 'Time management', label: 'Time management' },
    { key: 'Time management and ability to meet deadlines', label: 'Time management and ability to meet deadlines' },
    { key: 'Time management and prioritization', label: 'Time management and prioritization' },
    { key: 'Time management and prioritization skills', label: 'Time management and prioritization skills' },
    { key: 'Time management skills', label: 'Time management skills' },
    { key: 'Total quality management', label: 'Total quality management' },
    { key: 'Trade show and conference management', label: 'Trade show and conference management' },
    { key: 'Trademark law', label: 'Trademark law' },
    { key: 'Trading technology', label: 'Trading technology' },
    { key: 'Training and coaching skills', label: 'Training and coaching skills' },
    { key: 'Training and development', label: 'Training and development' },
    { key: 'Training delivery', label: 'Training delivery' },
    { key: 'Training skills', label: 'Training skills' },
    { key: 'Transmission system operations', label: 'Transmission system operations' },
    { key: 'Transportation management', label: 'Transportation management' },
    { key: 'Treasury accounting', label: 'Treasury accounting' },
    { key: 'Treasury management', label: 'Treasury management' },
    { key: 'Trend forecasting', label: 'Trend forecasting' },
    { key: 'Trend forecasting for the arts', label: 'Trend forecasting for the arts' },
    { key: 'Trial advocacy', label: 'Trial advocacy' },
    { key: 'Troubleshooting', label: 'Troubleshooting' },
    { key: 'Understanding of agile project management methodologies', label: 'Understanding of agile project management methodologies' },
    { key: 'Understanding of artificial intelligence and machine learning applications in marketing', label: 'Understanding of artificial intelligence and machine learning applications in marketing' },
    { key: 'Understanding of audience segmentation and targeting', label: 'Understanding of audience segmentation and targeting' },
    { key: 'Understanding of audio production and podcasting', label: 'Understanding of audio production and podcasting' },
    { key: 'Understanding of branding and brand voice', label: 'Understanding of branding and brand voice' },
    { key: 'Understanding of consumer behavior', label: 'Understanding of consumer behavior' },
    { key: 'Understanding of content distribution channels and strategies', label: 'Understanding of content distribution channels and strategies' },
    { key: 'Understanding of content strategy and content calendars', label: 'Understanding of content strategy and content calendars' },
    { key: 'Understanding of customer segmentation and targeting', label: 'Understanding of customer segmentation and targeting' },
    { key: 'Understanding of data visualization and infographic creation', label: 'Understanding of data visualization and infographic creation' },
    { key: 'Understanding of different writing styles and genres', label: 'Understanding of different writing styles and genres' },
    { key: 'Understanding of e-commerce marketplaces such as Amazon or eBay', label: 'Understanding of e-commerce marketplaces such as Amazon or eBay' },
    { key: 'Understanding of food trends and cuisines', label: 'Understanding of food trends and cuisines' },
    { key: 'Understanding of growth hacking techniques and strategies', label: 'Understanding of growth hacking techniques and strategies' },
    { key: 'Understanding of journalistic ethics and standards', label: 'Understanding of journalistic ethics and standards' },
    { key: 'Understanding of market trends and audience preferences', label: 'Understanding of market trends and audience preferences' },
    { key: 'Understanding of mobile app and game writing', label: 'Understanding of mobile app and game writing' },
    { key: 'Understanding of mobile marketing and mobile app development', label: 'Understanding of mobile marketing and mobile app development' },
    { key: 'Understanding of real estate law', label: 'Understanding of real estate law' },
    { key: 'Understanding of the scientific method', label: 'Understanding of the scientific method' },
    { key: 'Understanding of user experience (UX) design principles', label: 'Understanding of user experience (UX) design principles' },
    { key: 'Understanding of user-generated content and community management', label: 'Understanding of user-generated content and community management' },
    { key: 'Understanding of website design and development best practices', label: 'Understanding of website design and development best practices' },
    { key: 'Understanding of website optimization and conversion rate optimization (CRO)', label: 'Understanding of website optimization and conversion rate optimization (CRO)' },
    { key: 'Upstream marketing', label: 'Upstream marketing' },
    { key: 'Upstream operations', label: 'Upstream operations' },
    { key: 'Urban design', label: 'Urban design' },
    { key: 'User behavior analysis', label: 'User behavior analysis' },
    { key: 'User experience (UX) design', label: 'User experience (UX) design' },
    { key: 'User experience (UX) design: wireframing, prototyping, usability testing, and more.', label: 'User experience (UX) design: wireframing, prototyping, usability testing, and more.' },
    { key: 'User experience design', label: 'User experience design' },
    { key: 'User experience testing', label: 'User experience testing' },
    { key: 'User interface (UI) design', label: 'User interface (UI) design' },
    { key: 'User interface (UI) design: Sketch, Adobe XD, Figma, InVision, Zeplin, and more.', label: 'User interface (UI) design: Sketch, Adobe XD, Figma, InVision, Zeplin, and more.' },
    { key: 'User interface design', label: 'User interface design' },
    { key: 'User research', label: 'User research' },
    { key: 'UX/UI design and development', label: 'UX/UI design and development' },
    { key: 'Value engineering', label: 'Value engineering' },
    { key: 'Vendor and supplier management skills', label: 'Vendor and supplier management skills' },
    { key: 'Vendor management', label: 'Vendor management' },
    { key: 'Vendor management software', label: 'Vendor management software' },
    { key: 'Vendor selection', label: 'Vendor selection' },
    { key: 'Venture capital and private equity', label: 'Venture capital and private equity' },
    { key: 'Version control: Git, SVN, and more.', label: 'Version control: Git, SVN, and more.' },
    { key: 'VFX software', label: 'VFX software' },
    { key: 'Video editing software', label: 'Video editing software' },
    { key: 'Video marketing', label: 'Video marketing' },
    { key: 'Video production', label: 'Video production' },
    { key: 'Video production and editing', label: 'Video production and editing' },
    { key: 'Virtual assistants', label: 'Virtual assistants' },
    { key: 'Virtual reality', label: 'Virtual reality' },
    { key: 'Virtual reality development', label: 'Virtual reality development' },
    { key: 'Virtual reality development: Unity, Unreal Engine, and more.', label: 'Virtual reality development: Unity, Unreal Engine, and more.' },
    { key: 'Virtualization', label: 'Virtualization' },
    { key: 'Visual and spatial awareness', label: 'Visual and spatial awareness' },
    { key: 'Visual branding and design', label: 'Visual branding and design' },
    { key: 'Visual effects', label: 'Visual effects' },
    { key: 'Visual storytelling and narrative structure', label: 'Visual storytelling and narrative structure' },
    { key: 'Visualization', label: 'Visualization' },
    { key: 'Voice interface development: Amazon Alexa, Google Assistant, and more.', label: 'Voice interface development: Amazon Alexa, Google Assistant, and more.' },
    { key: 'Voice of the customer (VOC) analysis', label: 'Voice of the customer (VOC) analysis' },
    { key: 'Volunteer management', label: 'Volunteer management' },
    { key: 'Volunteer recruitment and management for arts events', label: 'Volunteer recruitment and management for arts events' },
    { key: 'Warehouse management', label: 'Warehouse management' },
    { key: 'Waste management', label: 'Waste management' },
    { key: 'Waste management and recycling', label: 'Waste management and recycling' },
    { key: 'Wastewater treatment', label: 'Wastewater treatment' },
    { key: 'Water management', label: 'Water management' },
    { key: 'Water pollution control and management', label: 'Water pollution control and management' },
    { key: 'Water quality monitoring and assessment', label: 'Water quality monitoring and assessment' },
    { key: 'Water resource management and conservation', label: 'Water resource management and conservation' },
    { key: 'Water resource monitoring and assessment', label: 'Water resource monitoring and assessment' },
    { key: 'Water treatment', label: 'Water treatment' },
    { key: 'Water treatment and management', label: 'Water treatment and management' },
    { key: 'Wealth management', label: 'Wealth management' },
    { key: 'Web accessibility: WCAG 2.1, ARIA, keyboard accessibility, and more.', label: 'Web accessibility: WCAG 2.1, ARIA, keyboard accessibility, and more.' },
    { key: 'Web analytics', label: 'Web analytics' },
    { key: 'Web and app development', label: 'Web and app development' },
    { key: 'Web content management systems', label: 'Web content management systems' },
    { key: 'Web development', label: 'Web development' },
    { key: 'Web development and coding', label: 'Web development and coding' },
    { key: 'Web development frameworks', label: 'Web development frameworks' },
    { key: 'Web performance optimization', label: 'Web performance optimization' },
    { key: 'Web scraping: Beautiful Soup, Scrapy, and more.', label: 'Web scraping: Beautiful Soup, Scrapy, and more.' },
    { key: 'Website accessibility', label: 'Website accessibility' },
    { key: 'Website maintenance', label: 'Website maintenance' },
    { key: 'Welding and fabrication', label: 'Welding and fabrication' },
    { key: 'Welding engineering', label: 'Welding engineering' },
    { key: 'Well engineering', label: 'Well engineering' },
    { key: 'Wetland and riparian management and restoration', label: 'Wetland and riparian management and restoration' },
    { key: 'White-collar crime', label: 'White-collar crime' },
    { key: 'Wildlife ecology and behavior', label: 'Wildlife ecology and behavior' },
    { key: 'Wildlife habitat management and restoration', label: 'Wildlife habitat management and restoration' },
    { key: 'Wildlife management', label: 'Wildlife management' },
    { key: 'Wildlife management and conservation', label: 'Wildlife management and conservation' },
    { key: 'Wildlife population estimation and modeling', label: 'Wildlife population estimation and modeling' },
    { key: 'Wind energy technologies and development', label: 'Wind energy technologies and development' },
    { key: 'Wind power', label: 'Wind power' },
    { key: 'Wireless communications', label: 'Wireless communications' },
    { key: 'Workforce planning', label: 'Workforce planning' },
    { key: 'Workplace wellness', label: 'Workplace wellness' },
    { key: 'Writing and editing', label: 'Writing and editing' },
    { key: 'Writing fluency and strong command of grammar and syntax', label: 'Writing fluency and strong command of grammar and syntax' },
    { key: 'Writing skills', label: 'Writing skills' },
    { key: 'Written communication', label: 'Written communication' },
    { key: 'X-ray crystallography knowledge and skills', label: 'X-ray crystallography knowledge and skills' }
];

export const skillsFilter = skillOptions.slice(0, 50).map(s => ({ option: s.key, label: s.label }));
