import { accountTypes } from "./accountTypes";
import { profileStatuses } from "./profileStatuses";

export const getAccountType = (user) => {
    return user?.attributes?.profile?.publicData?.accountType
};

export const getIsClient = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.client;
};

export const getIsConsultant = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.consultant;
};
export const getIsClientFromListing = (listing) => {
    return listing?.attributes?.publicData?.ownerType === accountTypes.client;
};

export const getIsConsultantFromListing = (listing) => {
    return listing?.attributes?.publicData?.ownerType === accountTypes.consultant;
};

export const getIsPendingVerification = (user) => {
    return false;//user?.attributes?.profile?.publicData?.profileStatus === profileStatuses.pendingVerification;
};

export const getIsVettedUser = (user) => {
    return true//user?.attributes?.profile?.publicData?.profileStatus === profileStatuses.vetted;
};


export const getUserStatus = (user) => {
    return user?.attributes?.profile?.publicData?.profileStatus ? user?.attributes?.profile?.publicData?.profileStatus : profileStatuses.none;
};


export const getUserAvailability = (user) => {
    return user?.attributes?.profile?.publicData?.availability;
};

export const getProfileListingId = (user) => {
    return user?.attributes?.profile?.protectedData?.profileListingId;
};